/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-12-15 17:52:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-17 15:45:14
 */
import { useState, useEffect, useCallback, useRef } from 'react';
import useAxios from './useAxios';
import { getCommandStatus, CommandStatus } from 'server/web/sendCommand'
import useSetState from 'util/useSetState';
import useIsAlive from '../useIsAlive';
import isOrderAgain from 'util/isOrderAgain';
import { QueryState } from 'util/interface';

type State = QueryState<CommandStatus>

function usePollingCommandStatus(_commandId?: string): [State, (commandId: string) => Promise<CommandStatus>]
function usePollingCommandStatus(_commandId?: string) {
  const [commandId, setCommandId] = useState(_commandId);
  const [{ data }, queryCommandStatus] = useAxios({
    axios: getCommandStatus,
    defaultStart: false,
  })
  const [state, setState] = useSetState<State>({ data, loading: false, success: false })
  const isAliveConfig = useIsAlive()
  const resolveFn = useRef<(value: CommandStatus | PromiseLike<CommandStatus>) => void>()

  useEffect(() => {
    setState({ data })
  }, [data, setState])

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined
    if (commandId && isAliveConfig.current.isAlive) {
      setState({ loading: true, success: false })
      const query = async (timer?: NodeJS.Timeout) => {
        const commandStatus = await queryCommandStatus({ payload: { commandId } })
        if (!isOrderAgain(commandStatus.status)) {
          setState({ loading: false, success: true })
          timer && clearInterval(timer)
          resolveFn.current && resolveFn.current(commandStatus)
        }
      }
      timer = setInterval(() => {
        query(timer)
      }, 1000)
      isAliveConfig.current.timerOfInterval.push(timer)
      query()
    }
  }, [commandId, isAliveConfig, queryCommandStatus, setState]);

  const promise = useCallback((commandId: string) => new Promise<CommandStatus>(resolve => {
    resolveFn.current = resolve
    setCommandId(commandId)
  }), [])

  return [state, promise]
}

export default usePollingCommandStatus;
