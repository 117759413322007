/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-07 09:46:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-19 17:34:28
 */
import { RequiredKeys } from 'util/interface'
import { TreeSelectProps } from '../interface'

type Keys = 'queryTime' | 'treeNodeFilterProp' | 'handleResult' | 'allowClear'

/**
 * 统一处理props中的默认值，并优化ts提示
 * @param {TreeSelectProps} props
 */
const useDefaultValue = (props: TreeSelectProps): RequiredKeys<TreeSelectProps, Keys> => {
  const {
    queryTime = 'onFocus',
    treeNodeFilterProp = 'title',
    allowClear = true,
    handleResult = (res: any) => res,
    ...extra
  } = props

  return { queryTime, treeNodeFilterProp, handleResult, allowClear, ...extra }
}

export default useDefaultValue
