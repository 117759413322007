import React, { PureComponent } from 'react'
import { render as ReactRender } from 'react-dom'
import { Position } from '../interface'
import MapContext, { MapContextProps } from '../map-context'
import { toLngLat } from '../util/comm'
import Overlay from 'ol/Overlay'
import OverlayPositioning from 'ol/OverlayPositioning'
import './infoWinfow.less'
import { isEqual } from 'lodash-es'

export interface InfoWindowProps {
    position?: Position
    visible?: boolean
    children?: React.ReactNode
    offset?: number[]
    /** 是否自动移动 */
    autoMove?: boolean
    onVisible?: (visible: boolean) => void
}

export default class infoWindow extends PureComponent<InfoWindowProps> {

    static contextType = MapContext
    context: MapContextProps
    infoWinfowDiv: HTMLDivElement
    infoWindow: Overlay

    componentDidMount(): void {
        this.creatInfoWindow()
    }

    creatInfoWindow() {
        const { offset } = this.props
        const { type } = this.context
        let x = offset ? offset[0] : 0
        let y = offset ? offset[1] - 10 : -10

        this.infoWinfowDiv = document.createElement('div')
        this.infoWinfowDiv.className = 'ol-tx-infoWindow'
        this.infoWinfowDiv.style.transform = `translate(calc(-50% - ${x}px), ${y}px)`
        this.setChild()

        this.infoWindow = new Overlay({
            position: toLngLat(this.props.position || [0, 0], type),
            positioning: OverlayPositioning.CENTER_CENTER,
            element: this.infoWinfowDiv,
            stopEvent: false,
            className: 'ol-tx-infoWindow-overlay',
        });

        if (this.props.position) {
            this.addInfoWindow(this.infoWindow)
        }
    }

    addInfoWindow(InfoWindow: Overlay) {
        const { map, type } = this.context
        map?.addOverlay(InfoWindow)
        if (this.props.autoMove && this.props.position) {
            map?.getView().setCenter(toLngLat(this.props.position, type))
        }
    }

    removeInfoWindow() {
        if (this.infoWindow) {
            const { map } = this.context
            map?.removeOverlay(this.infoWindow)
            this.setState({
                infoWindow: null
            })
        }
    }

    componentDidUpdate(prevProps: InfoWindowProps): void {
        if (this.infoWindow !== null) {
            if (this.props.visible !== prevProps.visible) {
                if (this.props.visible) {
                    this.addInfoWindow(this.infoWindow)
                } else {
                    this.removeInfoWindow()
                }
            }
            if (this.props.position && !isEqual(this.props.position, prevProps.position)) {
                this.infoWindow.setPosition(toLngLat(this.props.position, this.context.type))
            }
            this.setChild()
        }
    }

    componentWillUnmount() {
        if (this.infoWindow) {
            this.removeInfoWindow()
            this.setState({
                infoWindow: null
            })
        }
    }

    setChild() {
        if (this.infoWinfowDiv) {
            const { children } = this.props

            const _Div = <div
                className='ol-tx-infoWindow-body'
            >
                { children }
                <div className='ol-tx-infoWindow-triangle'></div>
            </div>

            ReactRender(_Div, this.infoWinfowDiv)
        }
    }

    render() {
        return null
    }
}
