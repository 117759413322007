import React, { PureComponent } from 'react'
import MapContext, { MapContextProps } from '../map-context'
import {
  Fill,
  Icon,
  Stroke,
  Style,
  Text,
} from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { FillStyleProps, StrokeStyleProps, TextStyleProps } from '../interface';
import { toLngLat } from '../util/comm';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';
import Circle from 'ol/geom/Circle';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import Projection from 'ol/proj/Projection'

export interface VectorProps {
  /** 矩形多边形数组 */
  features: FeatureProps[]
  fillStyle?: FillStyleProps
  strokeStyle?: StrokeStyleProps
  textStyle?: TextStyleProps
  showMarker?: boolean
  showLabel?: boolean
  view?: boolean
  zIndex?: number
  onLoad?: (layer: VectorLayer) => void
}

export interface FeatureProps {
  /** 覆盖物类型
   * marker 点
   * polygon 多边形
   * circle 圆
   * polyline 线
   * rectangle 矩形
   */
  type: string
  /** 区域范围 */
  bounds?: Array<number[]>
  center?: number[]
  radius?: number
  /** 覆盖物名称设置 */
  label?: string

}

interface LayerJsonArr {
  geometry: {
    coordinates: Coordinate[][],
    type: string
  },
  properties: {
    name: string
  },
  type: string
}

interface VectorStateProps {
  Layer: VectorLayer | null
}

/** 默认矩形 线路线条颜色 */
const defaultStrokeStyle: StrokeStyleProps = {
  color: 'rgba(234,39,46,0.5)',
  width: 2,
  lineDash: [10, 5]
}
/** 默认填充色 */
const defaultFillStyle: FillStyleProps = {
  color: 'rgba(255,159,159,0.3)',
}
/** 默认字体充色 */
const defaultTextStyle: TextStyleProps = {
  color: 'rgba(255,159,159,1)',
  font: '18px arial',
  outlineColor: 'rgba(255,255,255,0.9)',
  outlineWidth: 2,
  placement: 'point',
  textBaseline: 'middle',
  textAlign: 'center',
  overflow: true
}

const defaultImg = 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png'

export default class index extends PureComponent<VectorProps, VectorStateProps> {
  static contextType = MapContext
  context: MapContextProps
  source: VectorSource

  state: VectorStateProps = {
    Layer: null
  }

  setCircleCallback = (): void => {
    if (this.state.Layer !== null && this.props.onLoad) {
      this.props.onLoad(this.state.Layer)
    }
  }

  componentDidMount() {
    if (this.props.features.length) {
      this.createLayer()
    }
  }

  componentDidUpdate(pverProps: VectorProps) {
    if (this.props.features !== pverProps.features && this.props.features.length) {
      if (this.source) {
        this.removeFeature()
        this.updateVector()
      } else {
        this.createLayer()
      }
    }
  }

  createLayer() {
    const { map } = this.context

    this.source = new VectorSource({
      features: this.createVector(),
    });

    const _this = this
    const layer = new VectorLayer({
      source: this.source,
      zIndex: 30,
      style: _this.polygonStyleFunction.bind(_this),
    });

    map?.addLayer(layer)

    if (this.props.view) {

      this.viewFeature()
    }
    this.setState({
      Layer: layer
    })
  }

  viewFeature() {
    const { map } = this.context
    map?.getView().fit(this.source.getExtent())
  }

  removeFeature() {
    const FeaturesArr = this.source.getFeatures()
    // 移除矢量覆盖物
    for (const item of FeaturesArr) {
      this.source.removeFeature(item)
    }
  }

  updateVector() {
    const { view } = this.props

    this.source.addFeatures(this.createVector())

    if (view) {
      this.viewFeature()
    }
  }

  createVector() {
    const { type, map } = this.context
    const FeaturesArr = this.props.features.map((item): Feature<Geometry> => {
      if (item.type === 'polygon' || item.type === 'rect') {
        let polyGon = new Feature({ geometry: new Polygon([this.handlePath(item.bounds!)]) })
        polyGon.set('style', this.polygonStyleFunction())
        return polyGon
      } else if (item.type === 'circle') {

        const _radius = (item.radius || 100) / 0.8703674
        const _Circle = new Circle(toLngLat(item.center ? item.center : item.bounds![0], type), _radius)
        let circle = new Feature({ geometry: _Circle })

        circle.set('style', this.circleStyleFunction())

        return circle
      } else if (item.type === 'route') {
        let polyLine = new Feature({ geometry: new LineString(this.handlePath(item.bounds!)) })
        polyLine.set('style', this.polygonStyleFunction())
        return polyLine
      }
      let point = new Feature({ geometry: new Point(this.handlePath(item.bounds!)[0]) })
      point.set('style', this.pointStyleFunction())
      return point
    })
    // const pointArr = showMarker !== false ? this.props.features.map((item): Feature<Geometry> => {
    //   // 创建feature，一个feature就是一个点坐标信息
    //   let point = new Feature({
    //     geometry: new Point(toLngLat(item.center ? item.center : item.bounds![0], type))
    //   });
    //   point.setStyle(this.pointStyleFunction())
    //   return point
    // }) : []


    return FeaturesArr
  }

  handlePath(bounds: Array<number[]>) {
    return bounds.map(item => { return toLngLat(item, this.context.type) })
  }

  polygonStyleFunction() {

    let FillStyle = new Fill({
      ...defaultFillStyle,
      ...this.props.fillStyle
    })

    let StrokeStyle = new Stroke({
      ...defaultStrokeStyle,
      ...this.props.strokeStyle
    })

    // const _style = new Text({
    //   ...defaultTextStyle,
    //   ...this.props.textStyle,
    //   fill: new Fill({ color: this.props.textStyle?.color || defaultTextStyle.color }),
    //   text: item.label,
    //   stroke: new Stroke({
    //     color: this.props.textStyle?.outlineColor || defaultTextStyle.outlineColor,
    //     width: this.props.textStyle?.outlineWidth || defaultTextStyle.outlineWidth
    //   }),
    // })

    return new Style({
      stroke: StrokeStyle,
      fill: FillStyle,
      // text: _style
    })
  }

  pointStyleFunction() {
    return new Style({
      image: new Icon({
        src: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
        anchor: [0.5, 0.9],
        scale: 0.55,
      })
    })
  }

  circleStyleFunction() {
    return new Style({
      renderer: function renderer(coordinates, state) {
        var coordinates_0: any = coordinates[0];
        var x = coordinates_0[0];
        var y = coordinates_0[1];
        var coordinates_1: any = coordinates[1];
        var x1 = coordinates_1[0];
        var y1 = coordinates_1[1];
        var ctx = state.context;
        var dx = x1 - x;
        var dy = y1 - y;
        var radius = Math.sqrt(dx * dx + dy * dy);

        var innerRadius = 0;
        var outerRadius = radius * 1.4;

        var gradient = ctx.createRadialGradient(
          x,
          y,
          innerRadius,
          x,
          y,
          outerRadius
        );
        gradient.addColorStop(0, 'rgba(255,0,0,0)');
        gradient.addColorStop(0.6, 'rgba(255,0,0,0.2)');
        gradient.addColorStop(1, 'rgba(255,0,0,0.8)');
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
        ctx.fillStyle = gradient;
        ctx.fill();

        ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
        ctx.strokeStyle = 'rgba(255,0,0,1)';
        ctx.stroke();
      },
    })
  }



  componentWillUnmount() {
    if (this.source) {
      const allFeatures = this.source.getFeatures()
      for (const item of allFeatures) {
        this.source.removeFeature(item)
      }
    }
  }

  render() {
    return <></>
  }
}
