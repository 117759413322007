import React, { useEffect } from 'react'
import { Login } from './components'
import { removeLocal } from 'util/comm'
import { localKey } from 'store/user'
import classnames from 'classnames'
import './index.less'

const Index = () => {

  const TYPE = process.env.REACT_APP_TYPE!

  useEffect(() => {
    removeLocal([localKey.userInfo, localKey.token])
  }, [])

  return (
    <div className={ classnames("login-page-box", { 'concrete-login': TYPE === 'concrete' }) }>
      <Login />
    </div>
  )
}

export default Index
