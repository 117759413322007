/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-07-20 10:23:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-08-21 14:10:06
 */

import zh from './zh-CN'
import en from './en'

/**
 * 项目中使用的语言配置
 */
export const languageType = {
  zh: 'zh_CN',
  en: 'en_US'
}

const message = [
  {
    type: languageType.zh,
    title: '中文',
    message: zh
  },
  {
    type: languageType.en,
    title: 'English',
    message: en
  }
]

const getLocaleMessage = locale => {
  const intl = message.find(item => item.type === locale)
  if (intl) {
    return intl.message
  }
  return en
}

export default getLocaleMessage
