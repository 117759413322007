import React from 'react'
import classnames from 'classnames';
import './index.less';



interface LineProps {
  /**
   * 连线类型 默认
   */
  type?: 'corner' | 'line'
}

function line(props: LineProps) {
  const { type } = props
  return (
    <span className={ classnames('ztree-line', type, { 'line': !type }) }></span>
  )
}

export default line
