/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-25 15:32:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 15:41:15
 */
import React, { FC, useCallback } from 'react'
import { Upload, Button, message } from 'antd'
import { UploadProps } from 'antd/lib/upload'
import { ImportOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { useAxios } from 'util/hook'
import { tableStore } from 'store'

interface Props {
  imports?: Function
  tableName: string
}

const Import: FC<Props> = props => {
  const { imports, tableName } = props
  const { formatMessage: f } = useIntl()

  const [{ loading }, sendImport] = useAxios({
    axios: imports as any,
    onSuccess: (data: any) => {
      message.success(data)
      tableStore.queryTable(tableName)
    },
    defaultStart: false,
  })

  const customRequest = useCallback<NonNullable<UploadProps['customRequest']>>(
    async options => {
      const _form = new FormData()
      _form.append('uploadFile', options.file)
      sendImport({ payload: _form })
    },
    [sendImport],
  )

  const beforeUpload = useCallback<NonNullable<UploadProps['beforeUpload']>>(
    file => {
      const isXls = file.type === 'application/vnd.ms-excel'
      if (!isXls) message.warning(f({ id: 'tx000241', description: '只能上传xls格式的文件' }))
      return isXls
    },
    [f],
  )

  return (
    <Upload
      customRequest={customRequest}
      showUploadList={false}
      accept=".xls,application/vnd.ms-excel"
      beforeUpload={beforeUpload}
    >
      <Button loading={loading} icon={<ImportOutlined />}>
        {f({ id: 'tx000022', description: '导入' })}
      </Button>
    </Upload>
  )
}

export default Import
