import api from './index';
import axios from '../axios';
import { webAPIResponse } from './index.globals'
const MODULE = '/user'
/**
 * 用户中心 获取用户信息
 */
export const getUserDetail = () => axios.post<webAPIResponse.userCenter>(`${api}${MODULE}/info/detail`)

/**
 * 模糊搜索用户下拉框
 */
export const queryUserByName = (payload:any) => axios.post(`${api}${MODULE}/query/user/byName`, payload)