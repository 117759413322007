import React, { PureComponent } from 'react'
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import {
  Icon,
  Stroke,
  Style,
} from 'ol/style';
import { Options as IconOptions } from 'ol/style/Icon'
import { Vector as VectorLayer } from 'ol/layer';
import MapContext, { MapContextProps } from './map-context';
import { getCenter, toLngLat } from './util/comm';
import LineString from 'ol/geom/LineString';
import { getDistance } from 'ol/sphere';
import { Coordinate } from 'ol/coordinate';
import { getVectorContext } from 'ol/render';
import { isEqual } from 'lodash-es'
import { containsXY, boundingExtent } from 'ol/extent';

export enum PathToolType {
  /** 开始 */
  start = 'start',
  /** 暂停 */
  pause = 'pause',
  /** 停止 */
  stop = 'stop',
  /** 恢复 */
  recovery = 'recovery',
  /** 无操作 */
  none = 'none'
}

interface PathMarkerIcon extends IconOptions {
  /** 图片路径 */
  src?: string,
  scale?: number
  rotation?: number

  anchor?: number[]
}

export interface PathSimplifierProps {
  path: Array<number[]>
  /** 速度 */
  speed?: number
  tool?: string
  startIcon?: PathMarkerIcon
  endIcon?: PathMarkerIcon
  markerIcon?: PathMarkerIcon
  movePoint?: number
  view?: boolean
  zIndex?: number
  /** 移动监听 */
  onMove?: (obj: { idx: number, tail: number }) => void
  /** 移动结束 */
  onMoved?: () => void
}

const defaultStartIcon: PathMarkerIcon = {
  src: '',
}
const defaultEndIcon: PathMarkerIcon = {
  src: '',
}
const defaultMarkertIcon: PathMarkerIcon = {
  src: '/images/monitor/red.png',
  scale: 1.2
}

export default class PathSimplifier extends PureComponent<PathSimplifierProps> {

  static contextType = MapContext
  context: MapContextProps
  markerPoint: Point
  markerFeature: Feature
  source: VectorSource
  animating: string
  /** 总路段 */
  polyLine: LineString
  layer: VectorLayer
  /** 当前位置 */
  position?: Coordinate
  /** 当前下标 */
  index: number
  /** 当前帧数 */
  currentCount: number = 0
  /** 当前总步长 */
  count: number = 0
  /** 当前段的路径 */
  LineString: LineString
  /** 车辆方向 */
  rotation: number
  /** 两点间的距离 */
  distance: number = 0
  /** 上一个点 */
  startPoint: Coordinate = []
  /** 下一个点 */
  endPoint: Coordinate = []

  componentDidMount() {
    this.createPath()
  }

  componentDidUpdate(prevProps: PathSimplifierProps) {

    if (this.markerPoint) {
      if (this.props.tool !== prevProps.tool) {
        const { tool } = this.props
        if (tool === PathToolType.start) {
          this.startAnimation()
        }
        if (tool === PathToolType.stop) {
          this.stopAnimation(false)
        }
        if (tool === PathToolType.pause) {
          this.onPause()
        }
        if (tool === PathToolType.recovery) {
          this.onRecovery()
        }
      }

      if (this.props.movePoint !== undefined && this.props.movePoint !== prevProps.movePoint) {
        this.toPoint(this.props.movePoint)
      }

      if (this.props.path && !isEqual(this.props.path, prevProps.path)) {
        this.init()
        this.createPath()
      }
    }
  }

  componentWillUnmount() {
    if (this.source) {
      const FeaturesArr = this.source.getFeatures()
      const { map } = this.context
      // 移除矢量覆盖物
      for (const item of FeaturesArr) {
        this.source.removeFeature(item)
      }
      if (map && this.layer) {
        map.removeLayer(this.layer)
      }
    }
  }

  /** 初始化数据 */
  init() {
    const { map } = this.context
    this.animating = PathToolType.none
    this.index = 0
    this.position = undefined
    this.currentCount = 0
    this.count = 0
    this.rotation = 0
    if (map) {
      if (this.source) {
        const features = this.source.getFeatures()
        for (const item of features) {
          this.source.removeFeature(item)
        }
      }
    }
  }

  createPath() {
    const { path, markerIcon, startIcon, endIcon, view } = this.props
    const { map, type } = this.context
    if (path && path.length) {
      const _path = this.handlePath(path)
      this.polyLine = new LineString(_path)
      let polyLine = new Feature({
        type: 'route',
        geometry: this.polyLine,
      })
      polyLine.setStyle(new Style({
        stroke: new Stroke({
          color: 'rgb(0, 153, 198)',
          width: 2
        })
      }))

      const startMarker = new Feature({
        type: 'startMarker',
        geometry: new Point(toLngLat(path[0], type))
      })
      startMarker.setStyle(this.createMarkerStyle(startIcon || defaultStartIcon))

      const endMarker = new Feature({
        type: 'endMarker',
        geometry: new Point(toLngLat(path[path.length - 1], type))
      })
      endMarker.setStyle(this.createMarkerStyle(endIcon || defaultEndIcon))

      this.markerPoint = new Point(toLngLat(path[0], type))
      this.markerFeature = new Feature({
        type: 'marker',
        geometry: this.markerPoint
      })
      const rotation = this.getRotation(toLngLat(path[0], type), toLngLat(path[1], type))
      this.markerFeature.setStyle(this.createMarkerStyle({
        ...defaultMarkertIcon,
        ...markerIcon,
        rotation: rotation,
      }))
      this.source = new VectorSource({
        features: [polyLine, startMarker, endMarker, this.markerFeature],
      })
      this.layer = new VectorLayer({
        source: this.source,
        zIndex: this.props.zIndex || 10
      });

      if (map) {
        if (view !== false && this.polyLine) {
          map.getView().fit(this.polyLine.getExtent())
        }
        map.addLayer(this.layer)
      }
    }
  }

  handlePath(bounds: Array<number[]>) {
    return bounds.map(item => { return toLngLat(item, this.context.type) })
  }

  createMarkerStyle(icon: PathMarkerIcon) {
    const { src, anchor, scale, rotation, ...extra } = icon
    return new Style({
      image: new Icon({
        src: src || 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
        anchor: anchor || [0.5, 0.9],
        scale: scale || 0.55,
        rotation: rotation || 0,
        ...extra
      })
    })
  }

  /** 移动到某个点位 */
  toPoint(index: number = 0) {
    const { path, onMove } = this.props
    const { map, type } = this.context

    this.index = index
    if (index < path.length - 1) {
      this.setMrkerRotation(toLngLat(path[this.index], type), toLngLat(path[this.index + 1], type))
    } else { // 最后一个点位
      this.index = path.length - 1
      this.animating = PathToolType.stop
      this.setMrkerRotation(toLngLat(path[this.index - 1], type), toLngLat(path[this.index], type))
      this.layer.un('postrender', this.moveFeature.bind(this));
    }
    this.position = toLngLat(path[this.index], type)
    this.markerPoint.setCoordinates(this.position)

    if (map) {
      map.getView().setCenter(this.position);
    }

    if (onMove) {
      onMove({ idx: this.index, tail: 0 })
    }

    this.hideMarker()
    // if (this.animating === PathToolType.start || this.animating === PathToolType.recovery) {
    // } else {
    //   this.showMarker()
    // }
    if (this.animating === PathToolType.stop) {
      this.showMarker()
    }
    this.moveNext(this.index, true)
  }

  /** 移动下一个点
   * state 用于继续this.onmove()
   */
  moveNext(index: number = 0, toPoint: boolean = false) {
    this.index = index
    const { path } = this.props
    const { type } = this.context
    if (this.index < path.length - 1) {
      this.beforeMove(
        toLngLat(path[this.index], type),
        toLngLat(path[this.index + 1], type),
        toPoint
      )
    }
  }

  /** 移动前准备事件 */
  beforeMove(startPoint: Coordinate, endPoint: Coordinate, toPoint: boolean) {
    this.startPoint = startPoint
    this.endPoint = endPoint
    //当前的帧数
    this.currentCount = 0
    if (!toPoint) {
      this.onmove()
    } else {
      const { map } = this.context
      if (map) {
        map.render()
      }
    }

  }

  /** 计算运动中参数的变化 */
  onmove() {
    const { path, onMove, onMoved } = this.props
    const { map } = this.context
    const { speed } = this.props

    const step = (speed || 240) / 10

    //  获取总步长
    this.count = Math.round(getDistance(this.startPoint, this.endPoint) / step)
    // 获取方向
    this.rotation = this.getRotation(this.startPoint, this.endPoint)
    // 获取LineString实例
    this.LineString = new LineString([this.startPoint, this.endPoint])
    //如果小于1直接移动到下一点
    if (this.count < 1 && (this.animating !== PathToolType.stop && this.animating !== PathToolType.pause)) {
      this.moveNext(++this.index);
      return;
    }

    if (onMove) {
      onMove({
        idx: this.index > path.length ? path.length : this.index,
        tail: Number((this.currentCount / this.count).toFixed(6))
      })
    }

    //两点之间当前帧数大于总帧数的时候，则说明已经完成移动
    if (this.currentCount >= this.count) {
      //移动的点已经超过总的长度,则说明移动到最后一个点位
      if (this.index + 1 >= path.length - 1) {
        this.stopAnimation(true)
        if (onMoved) {
          onMoved()
        }
        return
      }
      this.moveNext(++this.index)
    } else {
      this.currentCount++
      this.position = this.LineString.getCoordinateAt(this.currentCount / this.count)
      // 获取当前可视范围
      if (map) {
        const extent = map.getView().calculateExtent(map.getSize());

        if (!containsXY(extent, this.position[0], this.position[1])) {
          map.getView().setCenter(this.position)
        }
        setTimeout(() => {
          map.render()
        }, 15)
      }
    }

  }

  /** 运动中 */
  moveFeature(event: any) {
    const { markerIcon } = this.props
    const vectorContext = getVectorContext(event);
    if (this.animating !== PathToolType.stop) {
      if (this.position) {
        // 创建运动中的矢量图标
        const feature = new Feature(new Point(this.position));
        vectorContext.drawFeature(feature, this.createMarkerStyle({
          ...defaultMarkertIcon,
          ...markerIcon,
          rotation: this.rotation
        }));

      }
      // 状态为start 或者 recovery时进行移动
      if (this.animating === PathToolType.start || this.animating === PathToolType.recovery) {
        this.onmove()
      }
    }
  }

  /** 获取当前方向 */
  getRotation(startPoint: Coordinate, endPoint: Coordinate): number {
    let rotation = 0;
    const dx = startPoint[0] - endPoint[0];
    const dy = startPoint[1] - endPoint[1];
    rotation = Math.atan2(dy, dx)
    return -rotation - 1.58
  }

  /** 设置marker点的方向 */
  setMrkerRotation(startPoint: Coordinate, endPoint: Coordinate) {
    const { markerIcon } = this.props
    this.rotation = this.getRotation(startPoint, endPoint)
    this.markerFeature.setStyle(this.createMarkerStyle({
      ...defaultMarkertIcon,
      ...markerIcon,
      rotation: this.rotation
    }))
  }

  /** 隐藏marker点 */
  hideMarker() {
    this.markerFeature.setStyle(new Style(undefined))
  }

  /** 显示marker点 */
  showMarker() {
    const { markerIcon } = this.props
    this.markerFeature.setStyle(this.createMarkerStyle({
      ...defaultMarkertIcon,
      ...markerIcon,
      rotation: this.rotation
    }))
  }

  /** 停止 */
  stopAnimation(state: boolean) {
    if (this.markerPoint) {
      const { path } = this.props
      const { type } = this.context
      this.animating = PathToolType.stop;
      if (!state) {
        this.index = 0
        this.setMrkerRotation(
          toLngLat(path[0], type),
          toLngLat(path[1], type)
        )
      }

      if (this.props.onMove) {
        this.props.onMove({ idx: 0, tail: 0 })
      }

      this.markerPoint.setCoordinates(this.polyLine.getCoordinateAt(state ? 1 : 0))
      if ((window as any).moveFeature) {
        this.layer.un('postrender', (window as any).moveFeature);
        (window as any).moveFeature = null
      }
      this.showMarker()
    }
  }

  /** 开始 */
  startAnimation() {
    if (this.markerPoint) {
      const { map } = this.context
      this.animating = PathToolType.start
      this.moveNext(this.index)  // 可以从任意位置开始
      this.hideMarker();
      (window as any).moveFeature = this.moveFeature.bind(this)
      this.layer.on('postrender', (window as any).moveFeature)
      map?.render()
    }
  }

  /** 暂停 */
  onPause() {
    if (this.animating === PathToolType.start) {
      this.animating = PathToolType.pause
    }
  }

  /** 恢复运动 */
  onRecovery() {
    if (this.animating === PathToolType.pause) {
      const { map } = this.context
      this.animating = PathToolType.start
      this.hideMarker()
      if (map) {
        map.render()
      }
    }
  }

  // 

  render() {
    return null
  }
}
