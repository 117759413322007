import React, { PureComponent } from 'react'
import { render as ReactRender } from 'react-dom'
import { InfoWindowProps } from './interface'
import { isEqual } from 'lodash-es'
import { toPixel, pointConvert } from './util/comm'


export default class InfoWindow extends PureComponent<InfoWindowProps> {
	map: any
	infoDOM: HTMLElement
	infoWindow: any

	constructor(props: InfoWindowProps) {
		super(props)
		this.map = props.__map__
		if (props.__map__) {
			setTimeout(() => {
				this.createInfoWindow(props)
			}, 13)
		}
	}

	componentWillReceiveProps(nextProps: InfoWindowProps) {
		if (this.map && nextProps.children && !isEqual(nextProps.children, this.props.children)) {
			this.refreshWindowLayout(nextProps)
		}
		if (nextProps.visible !== this.props.visible) {
			if (nextProps.visible) {
				this.showWindow(nextProps)
			} else {
				this.closeWindow()
			}
		}
		if (!isEqual(nextProps.position, this.props.position)) {
			this.move(nextProps)
		}
	}

	createInfoWindow(props: InfoWindowProps) {
		if (!props.position) return;
		const options = this.buildCreateOptions(props)

		if (props.type === 'amap') {
			this.infoWindow = new (window as any).AMap.InfoWindow(options)
		} else if (props.type === 'baidu') {
			this.infoWindow = new (window as any).BMapLib.InfoBox(this.map, options.content, options)
		}

		if (props.visible) {
			this.showWindow(props)
		}

		this.props.onInstanceCreated && this.props.onInstanceCreated()
	}

	/** 合并options */
	buildCreateOptions(props: InfoWindowProps) {
		const { isCustom, offset, content, size, ...extra } = props
		const options: Omit<InfoWindowProps, 'position'> = {}
		let _offset = offset
		options.isCustom = isCustom === false ? false : true

		if ('content' in props) {
			options.content = props.content
		} else {
			this.infoDOM = document.createElement('div')
			this.setChild(props)
			options.content = this.infoDOM
		}

		if (props.type === 'baidu') {
			if (_offset) {
				_offset = [_offset[0] + 5, _offset[0] + 30]
			}
		}
		options.offset = toPixel(_offset || [0, -30], props.type)
		return { ...options, ...extra }
	}

	/** 刷新content */
	refreshWindowLayout(props: InfoWindowProps) {
		this.setChild(props)
		this.setClassName(props)
	}

	/** 设置content */
	setChild(props: InfoWindowProps) {
		const child = props.children
		if (this.infoDOM && child) {
			ReactRender(child, this.infoDOM)
		} else {
			if (props.children) {
				// console.warn('因为你设置 isCustom 为 true，InfoWindow 的 Children 被忽略')
			}
		}
	}

	setClassName(props: InfoWindowProps) {
		if (this.infoDOM) {
			let baseClsValue = ''
			// 刷新 className
			if ('className' in props && props.className) {
				baseClsValue += props.className
			} else if (props.isCustom === true) {
				baseClsValue += 'amap_markers_pop_window'
			}
			this.infoDOM.className = baseClsValue
		}
	}

	async showWindow(props: InfoWindowProps) {
		if (this.infoWindow && props.position && props.position[0] && props.position[1]) {
			const point = await pointConvert([props.position], props.type)
			this.infoWindow.open(this.map, point[0])
		} else {
			this.createInfoWindow(props)
		}
	}

	closeWindow() {
		if (this.infoWindow) {
			this.infoWindow.close()
		}
	}

	async move(props: InfoWindowProps) {
		if (this.infoWindow && props.position) {
			const point = await pointConvert([props.position], this.props.type)
			this.infoWindow.setPosition(point[0])
		}
	}

	render() {
		return null
	}
}


