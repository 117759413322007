import React, { memo } from 'react'
import { Space, Empty } from 'antd'
import { useIntl } from 'react-intl'
import { DrawerDetailProps } from './interface'
import './index.less'

const DrawerDetail = (props: DrawerDetailProps) => {
  const { children, operate } = props
  const { formatMessage: f } = useIntl()

  return (
    <div className="tx-drawer-detail">
      <div className="tx-drawer-detail-body">{children || <Empty />}</div>
      {operate && (
        <div className="tx-drawer-detail-operate">
          <Space direction="vertical">
            {f({ id: 'tx000007', description: '操作' })}
            {operate}
          </Space>
        </div>
      )}
    </div>
  )
}

export default memo(DrawerDetail)
