import React, { useEffect, useState } from 'react'
import { DatePicker as AntdDatePicker } from 'antd'
import { RangePickerProps as AntdRangePickerProps } from 'antd/lib/date-picker'
import moment, { Moment } from 'moment'
import { RangePickerProps } from './interface'
import { isArray } from 'lodash-es'

const { RangePicker: AntdRangePicker } = AntdDatePicker

const RangePicker = (props: RangePickerProps) => {
  const { value, onChange } = props
  const [datesValue, setDatesValue] = useState<[Moment | null, Moment | null]>()
  const onDateChange: AntdRangePickerProps['onChange'] = (dates, dateStrings) => {
    onChange && onChange(dateStrings, dates)
  }

  useEffect(() => {
    if (value && isArray(value)) {
      const value_0 = value[0] ? moment(value[0]) : null
      const value_1 = value[1] ? moment(value[1]) : null
      setDatesValue([value_0, value_1])
    }
  }, [value])

  return (
    <AntdRangePicker {...(props as any)} value={datesValue || undefined} onChange={onDateChange} />
  )
}

export default RangePicker
