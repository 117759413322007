import React, { useEffect, FC, useState, useCallback, CSSProperties, ReactNode } from 'react'
import { Row, Col, Spin } from 'antd'
import classnames from 'classnames'
import Descriptions from './Descriptions'
import { getLangMessage as f } from 'util/comm'
import { Gutter } from 'antd/lib/grid/row'
import { isObject } from 'lodash-es'

import './index.less'

export interface InfoProps {
  items: InfoItems[]
  /** 显示数据 */
  data: any
  /** 每行显示多少数据 默认 1 */
  row?: number
  /**  */
  className?: string
  /** title的宽度 */
  titleWidth?: string | number
  /** 所有title的文本位置 */
  direction?: 'right' | 'left' | 'center'
  /** 栅格间隔，可以写成像素值或支持响应式的对象写法来设置水平间隔 { xs: 8, sm: 16, md: 24},或者使用数组形式同时设置 [水平间距, 垂直间距]
   * @description 必须是2的倍数，antd计算规则为
   * @example
   *  {
   *     marginLeft: gutter[0]! / -2,
   *     marginRight: gutter[0]! / -2,
   *  }
   */
  gutter?: Gutter | [Gutter, Gutter]
  style?: CSSProperties
  loading?: boolean
}

export interface InfoItems {
  /** title显示文本 优先级 render>title */
  title?: string
  /** 国际化 */
  intlTitle?: string
  /** 生成复杂数据的渲染函数, 优先级 render>title */
  render?: (value: any, record: any) => ReactNode
  children?: InfoItem[]
}

interface titleIntal {
  id: string
  description?: string
  defaultMessage?: string
  values?: { [key: string]: string }
}

export interface InfoItem {
  /** title显示文本 优先级 render>compose>title，当为数组时，会按照顺序填充路径 */
  dataIndex: string | string[]
  /** 国际化 */
  intlTitle?: string | titleIntal
  /** title */
  title?: string
  /** 用来组合显示值 优先级 render>compose>title */
  compose?: Array<string>
  /** 行占据数量 */
  row?: number
  /** 生成复杂数据的渲染函数, 优先级 render>compose>title */
  render?: (value: any, record: any) => ReactNode
  /** title的宽度 */
  titleWidth?: string | number
  /** 国际化备注 */
  description?: string
  /** 后缀，一般为单位等值，与render和compose互斥 */
  addonAfter?: React.ReactText
}

const Index: FC<InfoProps> = React.memo((props: InfoProps) => {
  const [init] = useState({})
  const { data = init, row, items, gutter, loading = false } = props
  const [infoItems, setInfoItems] = useState<any[]>([])

  useEffect(() => {
    handleItems(data)
  }, [data, items])

  /**
   * 创建Item
   * @param item
   */
  const handleItems = (data: any) => {
    let result = []
    try {
      // 构建最外层 Descriptions
      result = items.map((selfItems: InfoItems, index: number) => {
        const { title, intlTitle, render, children, ...extra } = selfItems

        let selefTitle: ReactNode = ''

        if (title || intlTitle) {
          if (render && title) {
            selefTitle = render(data[title], data)
          } else {
            selefTitle = f({ id: intlTitle ? `${intlTitle}` : `${title}` })
          }
        }

        return (
          <Descriptions {...extra} key={'tx_info_' + index} title={selefTitle}>
            {handleItem(children)}
          </Descriptions>
        )
      })
      setInfoItems(result)
    } catch (error) {
      new Error(`Info 组件 ==== handleItems：${error}`)
    }
  }

  /**
   * 处理每行显示多少个， 默认1个
   */
  const handleItem = useCallback(
    (selfItems?: InfoItem[]) => {
      if (!selfItems) return []
      const selfRow = row || 1

      return (
        <Row gutter={gutter || 0}>
          {selfItems.map((item: InfoItem, index: number) => {
            let span: number = 24 * ((item.row || 1) / selfRow)
            const { dataIndex } = item

            const key = Array.isArray(dataIndex)
              ? dataIndex.join()
              : dataIndex || 'info_item_' + index

            return (
              <Col span={span} key={key}>
                {' '}
                {createItem(item)}{' '}
              </Col>
            )
          })}
        </Row>
      )
    },
    [data],
  )

  /**
   * 处理单个Item
   */
  const createItem = useCallback(
    (item: InfoItem) => {
      const { dataIndex, render, compose, addonAfter, titleWidth, intlTitle, title } = item
      let value: any = ''
      if (Array.isArray(dataIndex)) {
        let _ = data
        dataIndex.forEach(item => {
          _ = _ && isObject(_) ? (_ as any)[item] : undefined
        })
        value = _
      } else {
        value = data[dataIndex]
      }
      if (render) {
        value = render(value, data)
      } else if (compose) {
        for (let key of compose) {
          value += data[key] || ''
        }
      } else if (addonAfter && value) {
        value += ` ${addonAfter}`
      }

      return (
        <Descriptions.Item
          label={
            title || (isObject(intlTitle) ? f(intlTitle) : intlTitle ? f({ id: intlTitle }) : '')
          }
          titleWidth={titleWidth || props.titleWidth}
          direction={props.direction}
        >
          {value}
        </Descriptions.Item>
      )
    },
    [data],
  )

  return (
    <div className={classnames('tx-info', props.className)} style={props.style ? props.style : {}}>
      <Spin spinning={loading}>{infoItems}</Spin>
    </div>
  )
})

export default Index
