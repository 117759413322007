import React, { useEffect, useRef } from 'react'
import { getLocal } from 'util/comm'

interface IframeProps {
  pagePath: string
  name: string
  classname?: string
}

function Iframe(props: IframeProps) {
  const token = getLocal('tx_token')
  const view: any = useRef()

  // useEffect(() => {
  //   setTimeout(() => {
  //     const hasIframe = document.querySelector(`#${props.name}`)
  //     view.current = hasIframe
  //     console.log(hasIframe, props.name);
  //     if (!hasIframe) {
  //       const iframe = createIframe(props, props.classname)
  //       view.current = iframe
  //     } else {
  //       view.current.style.display = 'block'
  //     }
  //   }, 0)

  //   return () => {
  //     if (view.current) {
  //       view.current.style.display = 'none'
  //     }
  //   }
  // }, [])

  // const createIframe = (data: IframeProps, className: string = 'app-content'): HTMLIFrameElement | null => {

  //   let iframe = document.createElement('iframe')
  //   const parentDom = document.querySelector(`.${className}`)
  //   iframe.src = data.pagePath + '?token=' + token
  //   iframe.id = data.name
  //   iframe.scrolling = 'yes'
  //   iframe.allowFullscreen = true
  //   if (parentDom) {
  //     parentDom.appendChild(iframe)
  //   }
  //   return iframe
  // }

  return (<div className='ka-wrapper'>
    <iframe
      src={ props.pagePath + '?token=' + token }
      id={ props.name }
      scrolling='yes'
      allowFullScreen
      title={ props.name }
    ></iframe>
  </div>)
}

export default React.memo(Iframe)
