import api from './index'
import axios from '../axios'
import { TableResponseType } from 'components/table/interface';
import { webAPIRequest, webAPIResponse, setWeightId } from './index.globals'
const MODULE = '/system/config'

/** 管理中心 系统管理 平台参数 拉取信息 */
export const querySetPlatform = () => axios.post<webAPIResponse.AddPlatform>(`${api}${MODULE}/get`)

/** 管理中心 系统管理 平台参数 保存*/
export const AddPlatform = (payload: webAPIRequest.AddPlatform) => axios.post<boolean>(`${api}${MODULE}/save`, payload)