/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-12 18:47:39
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-12 19:08:57
 */
import useAxios from './useAxios';
import { webAPIRequest, webAPIResponse } from 'server/web/index.globals'
import { AxiosConfigWithHandleRes } from './interface';
import { getCompanyList } from 'server/web/company';
import { arrayToTree, handleTreeData } from 'util/comm';

type Config = Omit<AxiosConfigWithHandleRes<webAPIRequest.CompanyTree, webAPIResponse.CompanyTree[], any>, 'axios' | 'handleResult'>

const useCompanyTree = (config: Config) =>
  useAxios({
    axios: getCompanyList,
    handleResult: data => {
      data.forEach(item => item?.isLeaf && delete item.isLeaf)
      return handleTreeData(arrayToTree(data, 'id', 'parentId'), {
        title: 'name',
        value: 'id',
        key: 'id',
      })
    },
    ...config
  })


export default useCompanyTree