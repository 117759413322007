import { PureComponent } from 'react'
import { PolygonProps } from './interface';
import { pointConvert, handleOptions, getPolygon, setView } from './util/comm'

const defaultOptions: Array<string> = [
  'path',
  'strokeColor',
  'strokeOpacity',
  'strokeWeight',
  'fillColor',
  'fillOpacity',
  'strokeStyle',
  'extData',
  'zIndex'
]

export default class Polygon extends PureComponent<PolygonProps> {

  map: any
  polygon: any

  constructor(props: PolygonProps) {
    super(props)
    this.map = props.__map__
    this.createPolygon()
  }

  componentWillReceiveProps(_nextProps: PolygonProps) {

  }

  componentWillUnmount() {
    this.remove()
  }

  async createPolygon() {
    if (this.props.path) {
      const options = handleOptions(this.props, defaultOptions)
      // 批量转换
      const path = await pointConvert(this.props.path, this.props.type)

      options.path = path
      options.map = this.map
      this.polygon = getPolygon(this.props.type, options)

      if (this.props.type === 'baidu') {
        this.map.addOverlay(this.polygon)
      }

      if (this.props.isView) {
        setView(this.props.type, this.map, this.polygon)
      }
    }
  }

  remove() {
    if (this.polygon) {
      if (this.props.type === 'baidu') {
        this.map.removeOverlay(this.polygon)
      } else if (this.props.type === 'amap') {
        this.map.remove(this.polygon)
      }
      this.polygon = null
    }
  }

  render() {
    return null
  }
}
