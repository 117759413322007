import { Position, IconProps } from "../interface";
import { transform } from 'ol/proj'
import { Coordinate } from "ol/coordinate";
import Point from 'ol/geom/Point';
import Style, { Options as StyleProps } from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import projzh from "projzh";

const PointType = 'EPSG:4326'

export const fromWGS84 = (position: Position): Position => {
  return projzh.datum.gcj02.fromWGS84(position, [], 0)
}

export const toWGS84 = (position: Position): Position => {
  return projzh.datum.gcj02.toWGS84(position, [], 0)
}

/** 转换坐物理标系
 * @param position 地图坐标
 * @param type 当前地图类型
 */
export const toLngLat = (position: Position, type: string): Coordinate => {
  const _position = position && position.length ? position : [0, 0]
  return transform([Number(_position[0]), Number(_position[1])], PointType, type)
}

/** 转换为当前图层坐标系
 * @param position 地图坐标
 * @param type 当前地图类型
 */
export const toMapLayerLngLat = (position: Position, type: string): Coordinate => {
  return transform(position, type, PointType)
}

/** 获取几何点实例
 * @param position 地图坐标
 * @param type 当前地图类型
 */
export const getPoint = (position: Position, type: string): Point => {
  return new Point(toLngLat(position, type))
}

/** 
 * 获取Icon实例
 * @package options icon设置项
 */
export const getIcon = (options: IconProps): Icon => {
  return new Icon(options)
}

/** 
 * 获取矢量容器样式实例
 * @package options icon设置项
 */
export const getStyle = (options: StyleProps): Style => {
  return new Style(options)
}

function rad2degr(rad: number) { return rad * 180 / Math.PI; }
function degr2rad(degr: number) { return degr * Math.PI / 180; }

export const getCenter = (data: Array<number[]>): Position => {
  let LATIDX = 0;
  let LNGIDX = 1;
  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;

  for (var i = 0; i < data.length; i++) {
    let lat = degr2rad(data[i][LATIDX]);
    let lng = degr2rad(data[i][LNGIDX]);
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng);
    sumY += Math.cos(lat) * Math.sin(lng);
    sumZ += Math.sin(lat);
  }

  let avgX = sumX / data.length;
  let avgY = sumY / data.length;
  let avgZ = sumZ / data.length;

  // convert average x, y, z coordinate to latitude and longtitude
  let lng = Math.atan2(avgY, avgX);
  let hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  let lat = Math.atan2(avgZ, hyp);
  console.log(rad2degr(lat), rad2degr(lng))
  return [rad2degr(lat), rad2degr(lng)];
}
