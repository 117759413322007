/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-07-14 09:42:17
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 18:51:48
 */
// import Table from './table'
import Table from './tablePro';

export default Table