/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-11-16 21:55:25
 * @LastEditors: 
 * @LastEditTime: 2021-01-20 14:49:27
 */
import React, { useState, useEffect } from 'react'
import classnames from 'classnames';
import '../index.less'

const speedType = {
  '1': 1,
  '2': 2,
  '4': 4,
  '8': 8,
  '16': 16,
} as const

export type SpeedType = typeof speedType

export interface myForWardProps {
  /**
   * 点击回调事件
   */
  onClick?: Function
  /**
   * 控制选择
   */
  value?: number,
  active: boolean
}

function ForwardContent(props: myForWardProps) {
  const { onClick, value, active } = props

  const [videoSpeed, setVideoSpeed] = useState<number>(1)

  useEffect(() => {
    setVideoSpeed(value!)
  }, [value])

  const click = (value: number) => {
    setVideoSpeed(value)
    if (onClick) {
      onClick(value)
    }
  }

  return (
    <div className='speed-content'>
      <span className={ classnames('speed', { 'speed-active': videoSpeed === 1 && active }) } onClick={ () => { click(1) } }>1倍</span>
      <span className={ classnames('speed', { 'speed-active': videoSpeed === 2 && active }) } onClick={ () => { click(2) } }>2倍</span>
      <span className={ classnames('speed', { 'speed-active': videoSpeed === 4 && active }) } onClick={ () => { click(4) } }>4倍</span>
      <span className={ classnames('speed', { 'speed-active': videoSpeed === 8 && active }) } onClick={ () => { click(8) } }>8倍</span>
      <span className={ classnames('speed', { 'speed-active': videoSpeed === 16 && active }) } onClick={ () => { click(16) } }>16倍</span>
    </div>
  )
}

export default ForwardContent
