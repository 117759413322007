/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-09-14 19:43:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-04 16:10:49
 */
import { useReducer as useReactReducer } from 'react';

interface ActionType<S> {
  type: keyof S | 'reset'
  data?: S
}

/**
 * 全局reducer
 * @param state 
 * @param action 
 */
const reducer = <State, Action extends ActionType<State>>(state: State, action: Action) => {
  if (action.type === 'reset') {
    if (!action.data) {
      throw new Error('请传入初始化数据')
    }
    return { ...action.data } as State
  }
  return { ...state, [action.type]: action.data ? action.data[action.type] : undefined } as State
}

/**
 * 自定义useReducer
 * @param initialState 初始值
 */
const useReducer = <State>(initialState: State) => {
  return useReactReducer<(state: State, action: ActionType<State>) => State>(reducer, initialState)
}

export default useReducer