import React, {
  useState,
  useEffect,
  useCallback,
  memo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Input, Tree, Spin, Empty, Button, Checkbox } from "antd";
import { isBoolean, isEqual, isUndefined } from "lodash-es";
import { LeftTreeOption, Action, State } from "../interface";
import { arrayToTree, getLangMessage as g, pleaseEnter as e } from "util/comm";
import { DataNode } from "rc-tree/lib/interface";
import { webAPIResponse } from "server/web/index.globals";
import { useIntl } from "react-intl";
import { Obj } from "util/interface";

const { Search } = Input;

interface LeftTreeProps {
  option: LeftTreeOption | true;
  state: State;
  dispatch: React.Dispatch<Action>;
}

interface ResItem extends webAPIResponse.CompanyTree {
  children?: ResItem[];
  depChildren?: DepItem[];
}

interface DepItem {
  children?: DepItem[];
  depName: string;
  companyId: string;
  id: string;
}
const LeftTree = forwardRef<any, any>(function LeftTree(
  props: LeftTreeProps,
  treeRef
) {
  const {
    dispatch,
    state: { treeParam, treeSearchValue },
  } = props;

  /** 对props.option做统一处理（将true转化为对象，并对一些字段赋予默认值） */
  const handleOption = (option: LeftTreeOption | true): LeftTreeOption => {
    const optionObj = isBoolean(option) ? {} : option;
    if (!optionObj.queryKey) {
      optionObj.queryKey = "companyName";
    }
    if (!optionObj.tableKey) {
      optionObj.tableKey = "companyId";
    }
    if (!optionObj.type) {
      optionObj.type = "company";
    }
    if (isUndefined(optionObj.showOption)) {
      optionObj.showOption = true;
    }
    return optionObj;
  };

  const [option] = useState(() => handleOption(props.option));
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  /** 默认展开第一层父级 */
  const [parentIds, setParentIds] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(option.showOption!);
  const [paramToTree, setParamToTree] = useState<Obj>({});
  const { formatMessage: f } = useIntl();
  const {
    query,
    queryKey,
    paramKey,
    tableKey,
    handleTableQueryParam,
    getQuery,
  } = option;

  useImperativeHandle(treeRef, () => ({
    resetClick() {
      queryData();
    },
  }));

  const handleTreeData = (data: ResItem[]): DataNode[] => {
    const handleDepChilds = (item: DepItem): DataNode => {
      const { companyId, id } = item;
      const res: DataNode = {
        key: `${companyId},${id}`,
        title: item.depName,
      };
      if (item.children) {
        res.children = item.children.map(handleDepChilds);
      }
      return res;
    };

    const handleChilds = (item: ResItem): DataNode => {
      const res: DataNode = {
        key: item.id,
        title: item.name,
      };
      if (item.children) {
        res.children = item.children.map(handleChilds);
      }
      if (item.depChildren) {
        const depChildren = item.depChildren.map(handleDepChilds);
        res.children = [...(res.children ? res.children : []), ...depChildren];
      }
      return res;
    };

    const tree = data.map(handleChilds);
    // console.log("tree")
    // console.log(tree)
    setParentIds(data.map((item) => item.id));
    return tree;
  };

  const sortChinese = (arr: any, dataLeven: string) => {
    const getValue = (option: any) => {
      // 参数： option 数组元素
      if (!dataLeven) return option;
      let data = option;
      dataLeven.split(".").filter((item: any) => {
        data = data[item];
      });
      return data + "";
    };
    return arr.sort((item1: any, item2: any) => {
      return getValue(item1).localeCompare(getValue(item2), "zh-CN");
    });
  };

  const queryData = useCallback(
    async (value?: string) => {
      if (query && queryKey) {
        setLoading(true);
        const padyload = paramKey
          ? { ...paramKey, [queryKey]: value }
          : { [queryKey]: value };
        const res = await query(padyload);
        setLoading(false);
        if (res) {
          const arr = sortChinese(res.data, "name");
          const treeData = handleTreeData(arrayToTree(arr, "id", "parentId"));
          setTreeData(treeData);
        }
      }
    },
    [query, queryKey]
  );

  useEffect(() => {
    queryData("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParamToTree((state) => ({ ...state, isQuerySub: +checkboxValue + "" }));
  }, [checkboxValue]);

  useEffect(() => {
    dispatch({ type: "treeParam", data: { treeParam: paramToTree } });
    dispatch({ type: "current", data: { current: 1 } });
  }, [paramToTree, dispatch]);

  useEffect(() => {
    if (getQuery) {
      getQuery(queryData, treeSearchValue);
    }
  }, [getQuery, queryData, treeSearchValue]);

  useEffect(() => {
    if (option.type === "companyAndDep") {
      if (treeParam) {
        const { companyIds, depId } = treeParam;
        if (!companyIds && !depId) {
          setSelectedKeys([]);
        }
        if (companyIds) {
          if (depId) {
            setSelectedKeys([`${companyIds[0]},${depId}`]);
          } else {
            setSelectedKeys([companyIds[0]]);
          }
        }
      }
    } else {
      // console.log(treeParam)
      const _ = treeParam![tableKey!];

      const keys = Array.isArray(_) ? _ : [_];
      setSelectedKeys(keys);
    }
  }, [treeParam, tableKey, option.type]);

  return (
    <div className="tx-table-tree-wrapper">
      <div className="tx-table-search-wrapper">
        <Search
          placeholder={e({ id: "tx130000", description: "企业" })}
          value={treeSearchValue}
          onChange={(e) => {
            const { value } = e.target;
            dispatch({
              type: "treeSearchValue",
              data: { treeSearchValue: value },
            });
          }}
          enterButton="查询"
          onSearch={queryData}
          style={{ width: "100%" }}
          size="large"
          allowClear
        />
      </div>

      <div className="tx-table-tree">
        <Spin spinning={loading}>
          {treeData.length > 0 ? (
            <>
              {option.showOption && (
                <div className="tx-table-tree-option">
                  <Button
                    type="link"
                    style={{ textAlign: "left", paddingLeft: 28 }}
                    onClick={() => {
                      dispatch({
                        type: "treeSearchValue",
                        data: { treeSearchValue: "" },
                      });
                      setParamToTree((state) => ({
                        isQuerySub: state.isQuerySub,
                      }));
                      handleTableQueryParam && handleTableQueryParam(undefined);
                      queryData("");
                    }}
                  >
                    {g(
                      { id: "tx000100", description: "全部企业" },
                      { value: "tx000109", value2: "tx130000" }
                    )}
                  </Button>
                  <Checkbox
                    checked={checkboxValue}
                    defaultChecked
                    onChange={(e) => setCheckboxValue(e.target.checked)}
                  >
                    {f({ id: "tx000046", description: "显示下级" })}
                  </Checkbox>
                </div>
              )}

              <div className="tx-table-tree-content">
                <Tree
                  showIcon
                  treeData={treeData}
                  defaultExpandedKeys={parentIds}
                  selectedKeys={selectedKeys}
                  onSelect={([key]) => {
                    if (option.type === "companyAndDep") {
                      const [companyId, depId] = isUndefined(key)
                        ? [undefined, undefined]
                        : String(key).split(",");
                      const payload = { companyId, depId };
                      const _ = handleTableQueryParam
                        ? handleTableQueryParam(payload)
                        : payload;
                      setParamToTree((state) => ({ ...state, ..._ }));
                    } else {
                      const _ = handleTableQueryParam
                        ? handleTableQueryParam(key)
                        : key;
                      setParamToTree((state) => ({ ...state, [tableKey!]: _ }));
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Spin>
      </div>
    </div>
  );
});

export default memo(LeftTree, isEqual);
