/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-07-31 15:46:36
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-09-16 15:21:20
 */
import { observable, action } from 'mobx';

class LoginStore {
  @observable showVeri = false //展示验证框
  @observable loginLoading = false //modal中登录的Loading
  @observable sliderValue = 0 //modal中滑块value值

  @action
  updateLoginStore = (key: keyof Omit<LoginStore, 'updateLoginStore'>, value: any) => {
    (this as any)[key] = value
  }
}

const loginStore: LoginStore = new LoginStore()

export { LoginStore }
export default loginStore