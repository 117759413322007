/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-10-06 09:12:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-30 16:16:26
 */
import { LngLat, IconType, LabelType, LabelProps, pointType } from '../interface'
import { isArray, isObject, isString } from 'lodash-es'
import { wgs84tobd09, wgs84togcj02 } from './convert'

export enum MapType {
    baidu = 'baidu',
    amap = 'amap'
}

/**
 * 
 * @param poition 
 * @param type 地图类型,默认 GD
 */
export const toLngLat = (poition: LngLat | [number, number], type: string = MapType.amap) => {
    const lng: number = isArray(poition) ? poition[0] : poition.lng
    const lat: number = isArray(poition) ? poition[1] : poition.lat

    if (type === MapType.baidu && !!(window as any).BMap) {
        return new (window as any).BMap.Point(lng, lat)
    }

    if (type === MapType.amap && !!(window as any).AMap) {
        return new (window as any).AMap.LngLat(lng, lat)
    }

}

/**
 * 
 * @param iconConfig 
 * @param type 地图类型,默认 GD
 */
export const toIcon = (iconConfig: IconType, type: string = MapType.amap) => {
    if (type === MapType.baidu && !!(window as any).BMap) {
        let config: Object | string = {}
        if (isString(iconConfig)) {
            return new (window as any).BMap.Icon(iconConfig, toSize([36, 36], type), config)
        } else {
            config = {
                // anchor: toSize(iconConfig.anchor, type),
                imageOffset: toSize(iconConfig.imageOffset, type),
                imageSize: toSize(iconConfig.imageSize, type),
            }
            return new (window as any).BMap.Icon(iconConfig.image, toSize(iconConfig.size, type), config)
        }
    } else if (type === MapType.amap && !!(window as any).AMap) {
        if (isString(iconConfig)) {
            return iconConfig
        } else {
            const config = {
                imageOffset: toPixel(iconConfig.imageOffset, type),
                imageSize: toSize(iconConfig.imageSize, type),
                size: toSize(iconConfig.size, type),
                image: iconConfig.image || ''
            }
            return new (window as any).AMap.Icon(config)
        }
    }
}

/**
 * @param size 
 * @param type 地图类型,默认 GD
 */
export const toSize = (size: [number, number] | undefined, type: string = MapType.amap) => {
    const _size = size ? size : [36, 36]
    if (type === MapType.baidu && !!(window as any).BMap) {
        return new (window as any).BMap.Size(_size[0], _size[1])
    } else if (type === MapType.amap && !!(window as any).AMap) {
        return new (window as any).AMap.Size(_size[0], _size[1])
    }
}

/**
 * @param size 
 * @param type 地图类型,默认 GD
 */
export const toPixel = (size?: [number, number], type: string = MapType.amap) => {
    const _size = size ? size : [18, 36]
    let _Pixel: any = null
    if (type === MapType.baidu && !!(window as any).BMap) {
        _Pixel = new (window as any).BMap.Size(_size[0], _size[1])
    } else if (type === MapType.amap && !!(window as any).AMap) {
        _Pixel = new (window as any).AMap.Pixel(_size[0], _size[1])
    }
    return _Pixel
}

/**
 * @param point 
 * @param type 地图类型,默认 GD
 */
export const toBounds = (point: Array<pointType>, type: string = MapType.amap) => {
    if (type === MapType.amap && !!(window as any).AMap) {
        return new (window as any).AMap.Bounds(
            point[0],
            point[2],
        )
    } else if (type === MapType.baidu && !!(window as any).BMap) {

        return point
    }
    return null
}

export const toLabel = (labelConfig: LabelType, type: string = MapType.amap) => {
    let _config: LabelProps = {
        content: '',
        offset: [10, 5],
        direction: 'bottom'
    }
    if (isObject(labelConfig)) {
        _config.content = labelConfig.content
        _config.direction = labelConfig.direction
        if (type === MapType.amap) {
            _config.offset = toPixel(labelConfig.offset, type)
        }
    } else {
        _config.content = `<span>${labelConfig}</span>`
    }

    if (type === MapType.amap) {
        return _config
    } else {
        return {}
    }
}

/** 坐标系转换
 * @param point 二维数组 [[106,29]]
 * @param type 地图类型,默认 GD
 */
export const pointConvert = (point: Array<[number, number]>, type: string = MapType.amap): Promise<Array<any>> => {
    return new Promise((resolve, _reject) => {
        if (point && point.length) {
            if (type === MapType.baidu && (window as any).BMap) {
                const _points = point.map(item => {
                    return toLngLat(wgs84tobd09(item[0], item[1]), type)
                })
                resolve(_points)
            } else if (type === MapType.amap && (window as any).AMap) {
                const _points = point.map(item => {
                    return toLngLat(wgs84togcj02(item[0], item[1]), type)
                })
                resolve(_points)
            }
        } else {
            resolve([])
        }
    })
}

export const handleOptions = (props: any, defalutOptions: Array<string>) => {
    const _options: any = {}
    for (const key in props) {
        if (defalutOptions.includes(key)) {
            _options[key] = props[key]
        }
    }
    return _options
}

export const isInArea = (point: pointType, southWest: pointType, northEast: pointType) => {
    const areaLatitude1 = southWest[1]
    const areaLongitude1 = southWest[0]
    const areaLatitude2 = northEast[1]
    const areaLongitude2 = northEast[0]
    const longitude = point[0]
    const latitue = point[1]
    if (isInRange(latitue, areaLatitude1, areaLatitude2)) {//如果在纬度的范围内
        if (areaLongitude1 * areaLongitude2 > 0) {//如果都在东半球或者都在西半球
            if (isInRange(longitude, areaLongitude1, areaLongitude2)) {
                return true;
            } else {
                return false;
            }
        } else {//如果一个在东半球，一个在西半球
            if (Math.abs(areaLongitude1) + Math.abs(areaLongitude2) < 180) {//如果跨越0度经线在半圆的范围内
                if (isInRange(longitude, areaLongitude1, areaLongitude2)) {
                    return true;
                } else {
                    return false;
                }
            } else {//如果跨越180度经线在半圆范围内
                const left: number = Math.max(areaLongitude1, areaLongitude2);//东半球的经度范围left-180
                const right: number = Math.min(areaLongitude1, areaLongitude2);//西半球的经度范围right-（-180）
                if (isInRange(longitude, left, 180) || isInRange(longitude, right, -180)) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    } else {
        return false;
    }
}

/**
 * 是否在区域范围内
 * @param point 
 * @param left 
 * @param right 
 */
const isInRange = (point: number, left: number, right: number) => {

    if (point >= Math.min(left, right) && point <= Math.max(left, right)) {
        return true;
    } else {
        return false;
    }
}

/**
 * 获取Polyline对象
 * @param type 地图类型
 * @param options PolylineOptions
 */
export const getPolyline = (type: string, options: any) => {
    switch (type) {
        case MapType.baidu:
            return new (window as any).BMap.Polyline(options.path, options);
        default:
            return new (window as any).AMap.Polyline(options)
    }
}

/**
 * 获取Polygon对象
 * @param type 地图类型
 * @param options PolygonOptions
 */
export const getPolygon = (type: string, options: any) => {
    switch (type) {
        case MapType.baidu:
            return new (window as any).BMap.Polygon(options.path, options);
        default:
            return new (window as any).AMap.Polygon(options)
    }
}

/**
 * 获取Circle对象
 * @param type 地图类型
 * @param options CircleOptions
 */
export const getCircle = (type: string, options: any) => {
    switch (type) {
        case MapType.baidu:
            return new (window as any).BMap.Circle(options.center, options.radius, options);
        default:
            return new (window as any).AMap.Circle(options)
    }
}

/**
 * 获取Rectangle对象
 * @param type 地图类型
 * @param options RectangleOptions
 */
export const getRectangle = (type: string, options: any) => {
    switch (type) {
        case MapType.baidu:
            options.path = options.bounds
            return getPolygon(type, options);
        default:
            return new (window as any).AMap.Rectangle(options)
    }
}

/** 设置覆盖物在地图中心 */
export const setView = (type: string, map: any, overlay: any) => {
    switch (type) {
        case MapType.baidu:
            if (overlay.getPosition) {
                map.setViewport(overlay.getPosition())
            } else if (overlay.getPath) {
                map.setViewport(overlay.getPath())
            } else if (overlay.getBounds) {
                map.setViewport(overlay.getBounds())
            }
            break;
        default:
            map.setFitView([overlay])
            break;
    }
}

/**
 * 
 * @param type 地图类型
 * @param eventType 事件类型
 * @param callback 回调函数
 */
export const EventListener = (type: string, overlay: any, eventType: string, callback: Function) => {
    switch (type) {
        case MapType.baidu:
            overlay.addEventListener(eventType, callback)
            break;
        default:
            overlay.on(eventType, callback)
            break;
    }
}

/**
 * 创建script标签
 * @param src 
 */
export const buildScriptTag = (src: string): HTMLScriptElement => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.src = src
    return script
}

/** 获取多边形中心点 */
export const getPolygnCenter = (path: Array<number[]>): number[] => {
    let lng: number = 0
    let lat: number = 0

    for (let item of path) {
        lng += item[0]
        lat += item[1]
    }

    return [
        Number((lng / path.length).toFixed(6)),
        Number((lat / path.length).toFixed(6))
    ]
}