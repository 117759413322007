/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-09-09 17:08:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 11:13:48
 */
import api from './index'
import axios from '../axios'
import { TableResponseType } from 'components/table/interface'
import { webAPIRequest, webAPIResponse, Id, CompanyIds } from './index.globals'

const MODULE = '/department'

export interface DepartmentTree {
  depName: string
  id: string
  children: DepartmentTree[]
}

export interface DepartmentTreePayload extends CompanyIds {
  noSelectDepId?: string
}

/** 分页查询车组 */
export const getDepartmentPage = (payload: webAPIRequest.DepartmentPage) => axios.post<TableResponseType<webAPIResponse.DepartmentPage>>(`${api}${MODULE}/list`, payload)

/** 查询车组详情 */
export const getDepartmentInfo = (payload: Id) => axios<webAPIResponse.DepartmentPage>({ url: `${api}${MODULE}/detail`, params: payload, method: 'POST' })
/** 添加车组 */
export const addDepartment = (payload: webAPIRequest.AddDepartment) => axios.post<boolean>(`${api}${MODULE}/save`, payload)

/** 修改车组 */
export const updateDepartment = (payload: webAPIRequest.UpdateDepartment) => axios.post<boolean>(`${api}${MODULE}/update`, payload)

/** 删除车组 */
export const deleteDepartment = (payload: Id) => axios<boolean>({ url: `${api}${MODULE}/del`, params: payload, method: 'POST' })

/** 车组模糊搜索 */
export const searchDepartment = (payload: webAPIRequest.SearchDepartment) => axios.post<webAPIResponse.SearchDepartment[]>(`${api}${MODULE}/selectByNameLike`, payload)

/** 车组信息 导出 */
export const exportDepartment = (payload: webAPIRequest.ExportDepartment) => axios.post<boolean>(`${api}${MODULE}/exportDepartment`, payload)

/** 车组树 */
export const departmentTree = (payload: DepartmentTreePayload) => axios<DepartmentTree[]>({ url: `${api}${MODULE}/tree`, data: payload, method: 'POST' })