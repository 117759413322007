import React, {Component} from 'react'

interface ComponentProps {
  default: any
}

interface MyState {
  Component: any
}

export default function (getComponent: any): any {
  class AsyncComponent extends Component<any, MyState> {
    constructor(props: any) {
      super(props)
      this.state = {
        Component: AsyncComponent.Component
      }
    }

    static Component = null

    componentWillMount() {
      if (!this.state.Component) {
        getComponent().then(({default: Component}: ComponentProps) => {
          AsyncComponent.Component = Component
          this.setState({
            Component
          })

        })
      }
    }

    render() {
      const {Component} = this.state
      if (Component) {
        return <Component {...this.props} />
      }

      return null
    }
  }

  return AsyncComponent
}