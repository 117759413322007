/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-12 19:06:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 11:14:21
 */
import useAxios from './useAxios';
import { AxiosConfigWithHandleRes } from './interface';
import { handleTreeData } from 'util/comm';
import { DepartmentTree, departmentTree, DepartmentTreePayload } from 'server/web/department';

type Config = Omit<AxiosConfigWithHandleRes<DepartmentTreePayload, DepartmentTree[], any>, 'axios' | 'handleResult'>

const useDepTree = (config: Config) =>
  useAxios({
    axios: departmentTree,
    handleResult: data => handleTreeData(data, { title: 'depName', key: 'id' }),
    ...config
  })


export default useDepTree