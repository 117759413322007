/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-09-14 18:36:48
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-15 22:46:05
 */
import api from "./index";
import axios from "../axios";
import { TableResponseType } from "components/table/interface";
import { Id, webAPIRequest, webAPIResponse } from "./index.globals";

const MODULE = "/basiccode";

type Item = webAPIResponse.BasicCodesItem;

export interface BasicCodesType {
  /** 终端运维-消失点查询-校准状态 */
  calibrationStatus?: Item[];
  /** 终端运维-消失点查询-选择设备-设备型号 */
  producttype?: Item[];
  /** 终端运维-安装-设备安装验收-验收状态 */
  installStatus?: Item[];
  /** 终端运维-安装-企业验收-网络类型 */
  netType?: Item[];
  /** 终端运维-安装-攻略维护-攻略类型 */
  guideType?: Item[];
  /** 终端运维-安装-攻略维护状态 */
  guideStatus?: Item[];
  /** 智能运维-安装-安装预约-预约状态 */
  orderStatus?: Item[];
  /** 终端运维-安装-安装预约-行驶证车主类型 */
  driverType?: Item[];
  /** 终端运维-设备-保险数据管理-结算状态 */
  settlementStatus?: Item[];
  /**终端运维-安装-dvr参数下发-方向 */
  businessModel?: Item[];
  /**终端运维-终端验收管理-校准图片状况-是否有效 */
  validType?: Item[];
  /** 终端运维-设备-保险数据管理-销售属性 */
  saleAttr?: Item[];
  /** 终端运维-设备-保险数据管理-险种 */
  insuranceType?: Item[];
  /** 终端运维-设备-保险数据管理-方向 */
  directType?: Item[];
  /** 终端运维-终端管理-图商拍照查询-图片来源 */
  cmdSource?: Item[];
  //  /** 终端运维-流量统计-企业流量统计- 来源类型 */
  // sourceType?: Item[]
  /** 查全部父类型名称 */
  root?: Item[];
  /** 视频存储器 */
  VideoDisk?: Item[];
  /** 传感器类型 */
  SensorType?: Item[];
  /** 旋转方向 */
  VehicleTurnToStatus?: Item[];
  /** 视频通道 */
  VideoChannel?: Item[];
  /** 视频请求类型 */
  VideoDataType?: Item[];
  /** 视频编码类型 */
  VideoEncoderType?: Item[];
  /** 音频编码类型 */
  AudioEncoderType?: Item[];
  /** 存储器类型 */
  StoreType?: Item[];
  /** 码流类型(一共两项，主码流和子码流) */
  StreamType?: Item[];
  /** 码流类型(一共三项，多了一个所有码流) */
  StreamAvType?: Item[];
  /** 附件类型 */
  AttachmentFileType?: Item[];
  /** 处理意见 */
  ProcessSuggest?: Item[];
  /** 终端类型 */
  TerminalType?: Item[];
  /** 终端状态 */
  TerminalState?: Item[];
  /** 终端绑定状态 */
  TerminalBindState?: Item[];
  /** sim卡状态 */
  SimcardStatus?: Item[];
  /** 运营商 */
  SimcardOperator?: Item[];
  /** 车牌颜色 */
  plateColor?: Item[];

  // qintian 新增
  // 地址类型
  addressType?: Item[];
  //协议类型
  protocol?: Item[];
  // 国标协议
  internationalProtocol?: Item[];
  // 部标协议
  depStandardProtocol?: Item[];
  // 音频格式
  AudioType?: Item[];
  // 视频格式
  VideoEncType?: Item[];
  // 通道号
  // Chld?: Item[];
  // 码流类型

  // 分辨率
  VideotapeResolution?: Item[];
  // 帧率
  // FrameRate?: Item[];
  // 画质
  // Quality?: Item[];
  // 硬盘
  hdSwitch?: Item[];

  /** 车辆营运状态 */
  RunStatus?: Item[];
  /** 车辆在线状态 */
  OnlineState?: Item[];
  /** 拍照尺寸 */
  Resolution?: Item[];
  /** 录音音频参数 */
  AudioFrequency?: Item[];
  /** 监听类型 */
  ListenTerminal?: Item[];
  /** 拍照类型 */
  FilmMode?: Item[];
  /** 报警来源 */
  AlarmSource?: Item[];
  /** 报警类型 */
  AlarmType?: Item[];
  /** 报警等级 */
  AlarmLevel?: Item[];
  /** 指数类型 */
  IndexType?: Item[];
  /** 视频字幕叠加类型 */
  PlatformVehicleOsd?: Item[];
  /** OSD字母叠加选项 */
  OsdText?: Item[];
  /** 音视频资源类型 */
  VideoResourceType?: Item[];
  /** 车辆GPS状态 */
  VehicleStatus?: Item[];
  /** 视频资源文件上传状态 */
  UploadStatus?: Item[];
  /** 终端命令执行状态 */
  TCommandStatus?: Item[];
  /** 车辆状态 */
  VehicleLocationStatus?: Item[];
  /** 车辆信号状态 */
  VehicleExtraState?: Item[];
  // /** 车辆报警状态 */
  // VehicleAlarmStatus?: Item[]
  /** 区域类型 */
  AreaType?: Item[];
  /** 方向 */
  Direction?: Item[];
  /** 系统相关文本类型 */
  SystemType?: Item[];
  /** 车辆类型 */
  VehicleType?: Item[];
  /** 准驾车型 */
  DrivingType?: Item[];
  /** 统计项 */
  StatisticType?: Item[];
  /** 媒体事件 */
  MediaEvent?: Item[];
  /** 报警督办级别 */
  SupervisionLevel?: Item[];
  /** 车辆行业类型 */
  IndustryType?: Item[];
  /** 保险类型 */
  InsuranceType?: Item[];
  /** 809处理结果 */
  DealStatus809?: Item[];
  /** 用户状态 */
  UserStatus?: Item[];
  /** 保存方式 */
  SaveType?: Item[];
  /** 定时时间单位 */
  IntervalType?: Item[];
  /** 下载条件类型 */
  UploadConditionType?: Item[];

  /** 文本信息 */
  Description?: Item[];
  /** 主动拍照策略 - 高级辅助驾驶系统 */
  PhotoStrategy?: Item[];
  /** 主动拍照策略 - 驾驶员状态监测 */
  DriverPhotoStrategy?: Item[];
  /** 主动拍照策略 - 湘标驾驶员状态监测 */
  DriverPhotoStrategyXB?: Item[];
  /** 拍照分辨率 */
  PhotoResolution?: Item[];
  /** 视频录制分辨率 */
  VideoTranscribeResolution?: Item[];
  /** 事件使能 - 高级辅助驾驶系统 */
  IncidentEnabled?: Item[];
  /** 事件使能 - 驾驶员状态监测 */
  DriverIncidentEnabled?: Item[];
  /** 报警使能 - 高级辅助驾驶系统 - 苏标 */
  AdaAslarmEnable?: Item[];
  /** 报警使能 - 高级辅助驾驶系统 - 湘标 */
  XbAdasAlarmEnabled?: Item[];
  /** 报警使能 - 车辆监测系统 - 湘标 */
  XbVehicleAlarmEnabled?: Item[];
  /** 报警使能 - 驾驶员状态监测 */
  AlarmEnable?: Item[];
  /** 报警使能 - 湘标驾驶员状态监测 */
  AlarmEnableXB?: Item[];
  /** 驾驶员身份识别触发 */
  DriverIdentification?: Item[];

  /** 驾驶员抓拍使能状态 */
  CaptureEnableStatus?: Item[];
  /** 离线人脸比对开关 - 人脸比对 */
  OfflineFaceContrast?: Item[];
  /** 语音播报比对结果使能 - 人脸比对 */
  VoicebroadcastContrast?: Item[];
  /** 设置类型 - 驾驶员信息设置 */
  DriverSettingType?: Item[];

  /** 断油电指令 */
  OilEleType?: Item[];
  /** 权限类型 */
  RightType?: Item[];
  /** 功能类型 */
  ButtonType?: Item[];
  /** 进区域报警类型 */
  InAreaAlarm?: Item[];
  /** 出区域报警类型 */
  OutAreaAlarm?: Item[];
  /** 视频监听自动关闭时间 */
  VideoTimeClose?: Item[];

  /** 终端参数 - 终端电话监听策略 */
  TerminalPhoneMonitoring?: Item[];
  /** 终端参数 - 汇报策略 */
  ReportingStrategy?: Item[];
  /** 终端参数 - 汇报方案 */
  ReportingPlan?: Item[];
  /** 终端参数 - 休眠唤醒模式 */
  SleepWakeMode?: Item[];
  /** 终端参数 - 唤醒条件类型 */
  WakeUpType?: Item[];
  /** 终端参数 - 定时唤醒日 */
  TimedWakeUpDay?: Item[];

  /** 用户设置-第三方地图 */
  MapType?: Item[];
  /** 用户设置-地图车辆标签 */
  VehicleLabelType?: Item[];
  /** 用户设置-实时状态显示类型 */
  VehicleDisplayState?: Item[];
  /** 用户设置-默认视频通道数 */
  VideoShowChannel?: Item[];

  /** 报警设置 - 弹窗颜色 */
  PopupColor?: Item[];
  /** 车标类型 */
  VehicleIconType?: Item[];
  /** 音视频状态通知 */
  NoticVideoStatus?: Item[];
  /** 车辆在线状态报表时长选择 */
  OfflineDuration?: Item[];
  /** 音视频实时传输控制指令 */
  VideoTransmissionControl?: Item[];
  /** 关闭音视频类型 */
  TurnOffVideoType?: Item[];
  /** 门锁控制状态 */
  DoorControlState?: Item[];
  /** 胎压单位 */
  TirePressureUnit?: Item[];

  /** 车辆续时方式 */
  ChangeType?: Item[];

  /** 编码模式 */
  StreamCodingMode?: Item[];
  /** 分辨率 */
  StreamResolution?: Item[];
  /** 视频报警屏蔽项 */
  VideoAlarmShield?: Item[];
  /** 批量指令-多媒体 */
  VideoChannelType?: Item[];
  /** 批量指令-多媒体 */
  ConnectCloudPlatform?: Item[];
  /** 升级状态 */
  upgradestatus?: Item[];
  /** 软件类型 */
  AdasAppType?: Item[];
  /** 指令状态 */
  commandStatus?: Item[];
  /** 执行方案 */
  executemodel?: Item[];
  /** 包类型 */
  packageType?: Item[];
  /** 自检类型 */
  detecttype?: Item[];
  /** 故障类型 */
  faultType?: Item[];
  /** 自检运算用字段 */
  detectaddition?: Item[];
  /** 自检运算用字段 */
  detectaddsubtype?: Item[];
  /** 自检运算用字段 */
  detectitemkey?: Item[];
  /** 自检运算用字段 */
  operationType?: Item[];
  /** 未上线、上线、离线 */
  ipAction?: Item[];
  devicestatus?: Item[];
  /** 批量下发指令中的指令类型 */
  commandType?: Item[];
  /** 终端管理  添加设备 天线类型 */
  aerialType?: Item[];
  /** 终端管理  添加设备 车辆类型 */
  vehType?: Item[];
  /** 终端管理  标签 */
  termTag?: Item[];
  /** 终端管理  属性 */
  attribute?: Item[];
  /** 终端标签统计  在线时间段*/
  periodTime?: Item[];
  /** 终端标签统计  统计项*/
  statisticalItem?: Item[];
  /** 指令查询  指令状态*/
  bdCommandStatus?: Item[];
  /** 终端管理  所属城市*/
  vechilecity?: Item[];
  /** 批量指令-使能 */
  enableSwitch?: Item[];
  /** 批量指令-电平 */
  electronBalance?: Item[];
  /** 批量指令-切屏 */
  changeScreen?: Item[];
  /** 批量指令-速度来源 */
  speedSource?: Item[];
  /** 批量指令-速度来源 */
  speedSourceWeb?: Item[];
  /** 批量指令-报警类型 */
  speedAlarmType?: Item[];

  /** 批量指令-录像类型 */
  recordType?: Item[];

  /** 批量指令-ADAS报警类型 */
  adasAlarmType?: Item[];
  /** 批量指令-DSM参数报警类型 */
  dsmAlarmType?: Item[];
  /** 批量指令-盲区参数报警类型 */
  bsdAlarmType?: Item[];
  /** 批量指令-串口 */
  serialport?: Item[];
  /** 批量指令-波特率 */
  baudrate?: Item[];
  /** 批量指令-外设 */
  peripheral?: Item[];
  /** 批量指令-服务器名称 */
  serverName?: Item[];
  /** 批量指令-IO序号 */
  IONo?: Item[];
  /** 批量指令-报警灵敏度 */
  alarmSensitivity?: Item[];
  /** 批量指令-子码流分辨率码表 */
  VideotapeResolutionSub?: Item[];

  /** 工单管理-紧急程度 */
  workorderurgencylevel?: Item[];
  /** 工单管理-工单状态 发起方 */
  workorderstatus?: Item[];
  /** 工单管理-工单状态 处理方 */
  workorderstatusdo?: Item[];
  /** 工单管理-满意度 */
  workordersatisfaction?: Item[];
  /** 工单管理-工单问题原因 */
  workorderproblem?: Item[];
  /** 白名单查询-是否端采 */
  yesno?: Item[];
}

export interface AlarmSourceTypeCodePayload {
  alarmSources: string[];
}

/** 报警来源和报警类型 */
export interface BasicCode {
  title: string;
  key: string;
  children: BasicCode[];
}

export interface DataDictionary {
  /** 父类类型 */
  basicdataTypeKey: string;
  /** 父类类型名称 */
  basicdataName: string;
  /** 键 */
  key: string;
  /** 文本值 */
  text: string;
  /** 显示排序 */
  sort: number;
  /** en文本值 */
  enText: string;
  /** remark */
  remark: string;
}

/** 批量码表查询 */
export const getBasicCodes = (payload: webAPIRequest.BasicCodes) =>
  axios.post<BasicCodesType>(`${api}${MODULE}/findBasicCodes`, payload);

/** 报警来源和报警类型查询 */
export const getAlarmSourceTypeCodeList = (
  payload: AlarmSourceTypeCodePayload
) =>
  axios.post<BasicCode[]>(
    `${api}${MODULE}/getAlarmSourceTypeCodeList`,
    payload
  );

/** 右键指令-视频下载专用的报警来源和报警类型查询 */
export const getAlarmTypeForVideoDownload = (
  payload: AlarmSourceTypeCodePayload
) =>
  axios.post<BasicCode[]>(
    `${api}${MODULE}/getAlarmTypeForVideoDownload`,
    payload
  );

/** 报警来源和报警类型查询(返回结果未经过用户设置过滤) */
export const getBasicCodeTree = () =>
  axios.post<BasicCode[]>(`${api}${MODULE}/getBasicCodeTree`);

/** 分页查询数据字典 */
export const getDataDictionaryPage = (
  payload: webAPIRequest.DataDictionaryPage
) =>
  axios.post<TableResponseType<webAPIResponse.DataDictionaryPage>>(
    `${api}${MODULE}/page/list`,
    payload
  );

/** 查询数据字典详情 */
export const getDataDictionaryDetail = (payload: Id) =>
  axios.post<webAPIResponse.DataDictionaryDetail>(
    `${api}${MODULE}/detail`,
    payload
  );

/** 添加数据字典 */
export const addDataDictionary = (payload: webAPIRequest.AddDataDictionary) =>
  axios.post<boolean>(`${api}${MODULE}/save`, payload);

/** 修改数据字典 */
export const updateDataDictionary = (
  payload: webAPIRequest.UpdateDataDictionary
) => axios.post<boolean>(`${api}${MODULE}/update`, payload);

/** 删除数据字典 */
export const deleteDataDictionary = (payload: Id) =>
  axios.post<boolean>(`${api}${MODULE}/del`, payload);

/** 查询区域树形结构 */
export const queryAreaTree = () =>
  axios.post<webAPIRequest.AreaTree[]>(`${api}${MODULE}/areaTree`);

/** 根据区域code查询区域全称 */
export const queryAreaNameByAreaCode = (payload: { code: string }) =>
  axios.post<string>(`${api}${MODULE}/get/regionName`, payload);
