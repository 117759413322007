import React, { useState, useEffect, ReactNode } from 'react'
import { Drawer, Button } from 'antd'
import { DrawerProps as AntdDrawerProps } from 'antd/es/drawer'
import Footer from './footer'
import './index.less'

export interface DrawerProps extends Omit<AntdDrawerProps, 'onClose' | 'visible'> {
  /** 确认点击事件 */
  onOk?: () => void
  /** 取消点击事件 */
  onCancel?: () => void
  /** 确认按钮文字 */
  okText?: string | JSX.Element
  /** 取消按钮文字 */
  cancelText?: string | JSX.Element
  /** 替换全部按钮 */
  footer?: JSX.Element
  /** 控制底部是否可见 */
  visiableFooter?: boolean
  /** 确认按钮是否可见 */
  okVisible?: boolean
  /** 取消按钮是否可见 */
  cancelVisible?: boolean
  /** 抽屉关闭回调事件 */
  onClose?: (visible: boolean) => void
  /** 异步请求状态加载 */
  loading?: boolean
  visible: boolean
  children?: ReactNode
}

const Index = (props: DrawerProps) => {
  const {
    onOk,
    onCancel,
    okText,
    cancelText,
    okVisible,
    cancelVisible,
    onClose,
    loading,
    footer,
    visiableFooter,
    visible: fVisible,
    ...extra
  } = props

  // const [visible, setVisible] = useState<boolean>(false)
  const [selfOkVisible, setSelfOkVisible] = useState<boolean>(true)
  const [selfCanbcelVisible, setSelfCanbcelVisible] = useState<boolean>(true)
  const [selfFooterVisible, setSelfFooterVisible] = useState<boolean>(true)

  // useEffect(() => {
  //   setVisible(fVisible)
  // }, [fVisible])

  useEffect(() => {
    if ('okVisible' in props) {
      setSelfOkVisible(okVisible!)
    }
  }, [okVisible])

  useEffect(() => {
    if ('cancelVisible' in props) {
      setSelfCanbcelVisible(cancelVisible!)
    }
  }, [cancelVisible])

  useEffect(() => {
    if ('visiableFooter' in props) {
      setSelfFooterVisible(visiableFooter!)
    }
  }, [visiableFooter])

  /** 点击遮罩层或右上角叉或取消按钮的回调 */
  const onSelfClose = () => {
    // setVisible(false)
    if (onClose) {
      onClose(false)
    }
  }

  /** 点击确认回调 */
  const onOkClick = () => {
    if (props.onOk) {
      props.onOk()
    }
  }

  /** 点击取消回调 */
  const onCancelClick = () => {
    onSelfClose()
    if (props.onCancel) {
      props.onCancel()
    }
  }

  return (
    <Drawer
      className="tx-drawer"
      getContainer={document.querySelector('.components-content') as HTMLElement}
      visible={fVisible}
      onClose={onSelfClose}
      maskClosable={false}
      destroyOnClose={true}
      keyboard={false}
      style={{ position: 'absolute' }}
      footer={
        selfFooterVisible && (
          <div className="tx-drawer-footer">
            {selfOkVisible ? (
              typeof okText === 'string' || !okText ? (
                <Button
                  loading={loading}
                  className="tx-drawer-footer-ok"
                  type="primary"
                  onClick={onOkClick}
                >
                  {okText || '确认'}
                </Button>
              ) : (
                okText
              )
            ) : null}
            {selfCanbcelVisible ? (
              typeof cancelText === 'string' || !cancelText ? (
                <Button className="tx-drawer-footer-cancel" onClick={onCancelClick}>
                  {cancelText || '取消'}
                </Button>
              ) : (
                okText
              )
            ) : null}
          </div>
        )
      }
      {...extra}
    >
      {fVisible && props.children}
    </Drawer>
  )
}

Index.Footer = Footer

export { Footer }
export default Index
