/*
 * @Description: 图片下载
 * @Author: 谢永红
 * @Date: 2020-07-06 17:05:26
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-09-22 19:50:11
 */
import moment from 'moment'

/**
 * 截屏-绘制画布
 * @param videoElement video元素
 * @param sim sim卡
 */
export const screenCapture = (videoElement: HTMLVideoElement, sim: string = '') => {
  // 创建画布
  const canvas: HTMLCanvasElement = document.createElement('canvas')

  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')
  // 设置画布大小
  canvas.width = videoElement.videoWidth || 100
  canvas.height = videoElement.videoHeight || 100
  // 处理跨域
  videoElement.crossOrigin = ""
  // 开始绘制
  console.log(videoElement);
  (ctx as any).drawImage(videoElement, 0, 0, canvas.width || 100, canvas.height || 100)
  const base64: string = canvas.toDataURL('images/jpg')
  downloadImage(base64, sim)
}

/**
 * 下载图片
 * @param base64 
 * @param sim 
 */
const downloadImage = (base64: string, sim?: string) => {
  //设置下载图片的格式
  const type: string = "jpeg";
  //将canvas保存为图片
  const imgData: string = base64.replace(imgType(type), "image/octet-stream");

  const filename: string = sim + "_" + moment().format('YYYYMMDDHHmmss') + "." + type; //下载图片的文件名

  saveFile(imgData, filename)
}

/**
 * 保存图片
 * @param imgData 
 * @param fileName 
 */
const saveFile = (imgData: string, fileName: string) => {
  if (window.navigator.msSaveOrOpenBlob) {
    const bstr = atob(imgData.split(',')[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    const blob = new Blob([u8arr])
    window.navigator.msSaveOrOpenBlob(blob, fileName)
  } else {
    // 这里就按照chrome等新版浏览器来处理
    const a = document.createElement('a')
    a.href = imgData
    a.setAttribute('download', fileName);
    (window as any).document.querySelector('body').appendChild(a)
    a.click();
    (window as any).document.querySelector('body').removeChild(a)
  }
}

/**
 * 获取图片类型
 * @param ty 
 */
const imgType = (ty: string): string => {
  const type: string = ty.toLowerCase().replace(/jpg/i, "jpeg");
  const r: RegExpMatchArray | null = type.match(/png|jpeg|bmp|gif/);

  return "image/" + r![0]
}