/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-07-22 10:35:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-08-20 17:26:22
 */
import { observable, action } from 'mobx';
import { getLocal, setLocal } from 'util/comm'
import getLocaleMessage, { languageType } from 'locale';

/**
 * 获取浏览器语言
 */
const systemLanguage: string = navigator.language || (navigator as any).browserLanguage

/**
 * 浏览器语言和项目语言的映射关系
 */
const languageMap = {
  'zh-CN': languageType.zh,
  'zh': languageType.zh,
  'en-US': languageType.en,
  'en': languageType.en
}

/**
 * 默认语言
 */
const defaultLang: string = getLocal('lang') !== null ? (getLocal('lang') as string) : (languageMap as any)[systemLanguage] || languageType.en
setLocal('lang', defaultLang)

class IntlStore {
  @observable lang = defaultLang
  @observable intl = getLocaleMessage(defaultLang)

  @action
  updateIntl = (value: string) => {
    this.lang = value
    this.intl = getLocaleMessage(value)
    setLocal('lang', value)
  }

}

const intlStore: IntlStore = new IntlStore()

export { IntlStore }
export default intlStore