/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-06-08 09:48:36
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-21 17:23:44
 */
// import { lazy } from 'react'
import { RouteProps } from "react-router-dom";
import * as H from "history";
import lazy from "./async";

export interface RoutePropsConfig extends RouteProps {
  name: string;
  intlTitle?: string;
  path?: string;
  pagePath?: string;
  /** 是否为标签页， false 单独打开浏览器标签 */
  isTabs?: boolean;
  /** 是否做路由状态保存 */
  isAlive?: boolean;
  /** 路由跳转 */
  redirect?: H.LocationDescriptor;
  icon?: string;
  isIframe?: boolean;
  children?: RoutePropsConfig[];
}

// 首页
const Home = lazy(() => import("view/home"));
// 驾驶分析
const DrivingAnalysis = lazy(() => import("view/drivingAnalysis"));
// 企业风控
const CompanyControl = lazy(() => import("view/companyControl"));
// 看板
const WatchfulWaiting = lazy(() => import("view/watchfulWaiting"));

// 巡检
const Inspection = lazy(() => import("view/inspection"));

// 设备运维----start
const DeviceligentOperation = lazy(() => import("view/deviceligentOperation"));
// 设备--设备管理
const DeviceManagementd = lazy(
  () => import("view/deviceligentOperation/device/deviceManagement")
);

// 设备--终端指令查询
const Commandd = lazy(
  () => import("view/deviceligentOperation/device/command")
);

// 设备--设备最新自检查询
const LatestSelfCheckd = lazy(
  () =>
    import("view/deviceligentOperation/device/latestSelfCheck/latestSelfCheck")
);
// 设备--设备历史自检查询
const PastSelfCheckd = lazy(
  () => import("view/deviceligentOperation/device/pastSelfCheck")
);
// 安装验收管理--安装预约
const InstallationAppointment = lazy(
  () => import("view/deviceligentOperation/install/installationAppointment")
);
// 安装--安装验收
const InstallationAcceptance = lazy(
  () => import("view/deviceligentOperation/install/installationAcceptance")
);
// 安装验收管理--终端安装验收
const EquipmentAcceptance = lazy(
  () => import("view/deviceligentOperation/install/equipmentAcceptance")
);
// 安装验收管理--预约客户
const BookingCustomers = lazy(
  () => import("view/deviceligentOperation/install/bookingCustomers")
);
// 安装验收管理--预约二维码
const AppointmentQrCode = lazy(
  () => import("view/deviceligentOperation/install/appointmentQrCode")
);

// 系统管理--企业管理
const BusinessManagementd = lazy(
  () => import("view/deviceligentOperation/systems/businessManagement")
);
// 系统管理--操作员管理
const OperatorManagementd = lazy(
  () => import("view/deviceligentOperation/systems/operatorManagement")
);
// 系统管理--角色管理
const UserManagementd = lazy(
  () => import("view/deviceligentOperation/systems/userManagement")
);
// 系统管理--安装点管理
// const InstallationManagement = lazy(() => import('view/deviceligentOperation/systems/installationManagement'))

// 安装厂商管理--安装厂商管理
const ManufacturerManagementd = lazy(
  () => import("view/deviceligentOperation/manufacturer/manufacturerManagement")
);
// 安装厂商管理--安装厂商操作员管理
const ManufacturerOperatorManagementd = lazy(
  () =>
    import(
      "view/deviceligentOperation/manufacturer/manufacturerOperatorManagement"
    )
);
// 安装厂商管理--安装厂商角色管理
const ManufacturerRoleManagementd = lazy(
  () =>
    import("view/deviceligentOperation/manufacturer/manufacturerRoleManagement")
);

// 设备厂商管理--设备厂商管理
const CustomerManagementd = lazy(
  () => import("view/deviceligentOperation/customer/customerManagement")
);
// 设备厂商管理--设备厂商操作员管理
const CustomerOperatorManagementd = lazy(
  () => import("view/deviceligentOperation/customer/customerOperatorManagement")
);
// 设备厂商管理--设备厂商角色管理
const CustomerRoleManagementd = lazy(
  () => import("view/deviceligentOperation/customer/customerRoleManagement")
);

// 设备运维----end

// 终端运维------start
const IntelligentOperation = lazy(() => import("view/intelligentOperation"));
// 参数管理--消失点查询
const VanishingPoint = lazy(
  () => import("view/intelligentOperation/parameter/vanishingPoint")
);
// 参数管理--参数设置
const ParameterSetting = lazy(
  () => import("view/intelligentOperation/parameter/parameterSetting")
);
// 参数管理--DVR参数下发
const DvrParameterDistribution = lazy(
  () => import("view/intelligentOperation/parameter/dvrParameterDistribution")
);
// 参数管理--终端默认参数设置
const TerminalParameterSetting = lazy(
  () => import("view/intelligentOperation/parameter/terminalParameterSetting")
);
// 参数管理--服务器默认参数设置
const ServerParameterSetting = lazy(
  () => import("view/intelligentOperation/parameter/serverParameterSetting")
);

// 终端验收管理--校准图片比对情况
const ComparisonPictures = lazy(
  () => import("view/intelligentOperation/install/comparisonPictures")
);
// 终端验收管理--企业验收
const EnterpriseAcceptance = lazy(
  () => import("view/intelligentOperation/install/enterpriseAcceptance")
);
// 终端验收管理--攻略维护
const StrategyMaintenance = lazy(
  () => import("view/intelligentOperation/install/strategyMaintenance")
);
// 工单管理--工单创建
const WorkOrderCreation = lazy(
  () => import("view/intelligentOperation/workOrder/workOrderCreation")
);
// 工单管理--我的工单
const WorkOrderMine = lazy(
  () => import("view/intelligentOperation/workOrder/workOrderMine")
);
// 工单管理--工单分配
const WorkOrderAssign = lazy(
  () => import("view/intelligentOperation/workOrder/workOrderAssign")
);

// 工单管理--工单处理
const WorkOrderProcess = lazy(
  () => import("view/intelligentOperation/workOrder/workOrderProcess")
);

// 工单管理--工单追踪
const WorkOrderTrack = lazy(
  () => import("view/intelligentOperation/workOrder/workOrderTrack")
);
// 统计报表--产品运营监控分析报表
const ProductAnalysisReport = lazy(
  () => import("view/intelligentOperation/reportform/productAnalysisReport")
);
// 统计报表--设备运营监控分析报表
const DeviceAnalysisReport = lazy(
  () => import("view/intelligentOperation/reportform/deviceAnalysisReport")
);
// 统计报表--升级统计报表
const UpgradeStatisticalReport = lazy(
  () => import("view/intelligentOperation/reportform/upgradeStatisticalReport")
);
// 统计报表--设备发货数量报表
const Shipments = lazy(
  () => import("view/intelligentOperation/reportform/shipments")
);
// 统计报表--版本号统计报表
const VersionStatistical = lazy(
  () => import("view/intelligentOperation/reportform/versionStatistical")
);

// 流量统计--终端流量统计报表
const TerminalFlowReport = lazy(
  () => import("view/intelligentOperation/flowStatistics/terminalFlowReport")
);
// 流量统计--企业流量统计报表
const EnterpriseFlowReport = lazy(
  () => import("view/intelligentOperation/flowStatistics/enterpriseFlowReport")
);
// 流量统计-月流量统计报表
const MonthFlowReport = lazy(
  () => import("view/intelligentOperation/flowStatistics/monthFlowReport")
);
// 流量统计--客户日流量统计报表
const CustomerFlowReport = lazy(
  () => import("view/intelligentOperation/flowStatistics/customerFlowReport")
);
// 流量统计-客户月流量统计报表
const CustomerMonthFlowReport = lazy(
  () =>
    import("view/intelligentOperation/flowStatistics/customerMonthFlowReport")
);
// 流量统计-终端月流量统计
const TerminalMonthFlowReport = lazy(
  () =>
    import("view/intelligentOperation/flowStatistics/terminalMonthFlowReport")
);

// 黑白名单管理--白名单查询
const WhiteList = lazy(
  () => import("view/intelligentOperation/blackWhiteList/whiteList")
);
// 黑白名单管理--黑名单查询
const BlackList = lazy(
  () => import("view/intelligentOperation/blackWhiteList/blackList")
);
// 黑白名单管理--企业白名单查询
const EnterpriseWhiteList = lazy(
  () => import("view/intelligentOperation/blackWhiteList/enterpriseWhiteList")
);

// 客户管理--客户管理
const CustomerManagement = lazy(
  () => import("view/intelligentOperation/customer/customerManagement")
);
// 客户管理--客户操作员管理
const CustomerOperatorManagement = lazy(
  () => import("view/intelligentOperation/customer/customerOperatorManagement")
);
// 客户管理--客户角色管理
const CustomerRoleManagement = lazy(
  () => import("view/intelligentOperation/customer/customerRoleManagement")
);

// 安装厂商管理--安装厂商管理
const ManufacturerManagement = lazy(
  () => import("view/intelligentOperation/manufacturer/manufacturerManagement")
);
// 安装厂商管理--安装厂商操作员管理
const ManufacturerOperatorManagement = lazy(
  () =>
    import(
      "view/intelligentOperation/manufacturer/manufacturerOperatorManagement"
    )
);
// 安装厂商管理--安装厂商角色管理
const ManufacturerRoleManagement = lazy(
  () =>
    import("view/intelligentOperation/manufacturer/manufacturerRoleManagement")
);

// 系统管理--企业管理
const BusinessManagement = lazy(
  () => import("view/intelligentOperation/enterprise/businessManagement")
);
// 系统管理--操作员管理
const OperatorManagement = lazy(
  () => import("view/intelligentOperation/enterprise/operatorManagement")
);
// 系统管理--角色管理
const UserManagement = lazy(
  () => import("view/intelligentOperation/enterprise/userManagement")
);
// 系统管理--数据加载
const CacheLoading = lazy(
  () => import("view/intelligentOperation/enterprise/cacheLoading")
);

// 终端信息管理--设备指令查询
const Command = lazy(() => import("view/intelligentOperation/device/command"));
// 终端信息管理--设备信息管理
const DeviceInfo = lazy(
  () => import("view/intelligentOperation/device/deviceInfo")
);
// 终端信息管理--设备管理
const DeviceManagement = lazy(
  () => import("view/intelligentOperation/device/deviceManagement")
);
// 终端信息管理--设备升级状况
const DeviceUpgrade = lazy(
  () => import("view/intelligentOperation/device/deviceUpgrade")
);
// 终端信息管理--设备版本管理
const DeviceVersion = lazy(
  () => import("view/intelligentOperation/device/deviceVersion")
);
// 终端信息管理--设备最新自检查询
const LatestSelfCheck = lazy(
  () =>
    import("view/intelligentOperation/device/latestSelfCheck/latestSelfCheck")
);
// 终端信息管理--设备历史自检查询
const PastSelfCheck = lazy(
  () => import("view/intelligentOperation/device/pastSelfCheck")
);
// 终端信息管理--设备拍照查询
const Photo = lazy(() => import("view/intelligentOperation/device/photo"));
// 终端信息管理--图商拍照查询
const PicturePhoto = lazy(
  () => import("view/intelligentOperation/device/picturePhoto")
);
// 终端信息管理--保险数据管理
const InsuranceManagement = lazy(
  () => import("view/intelligentOperation/device/insuranceManagement")
);
// 终端运维------end

// 安装厂商端------start
const InstallManufacturer = lazy(() => import("view/installManufacturer"));
// 安装验收管理--安装验收
const ManufacturerAcceptance = lazy(
  () => import("view/installManufacturer/install/manufacturerAcceptance")
);
// 安装厂商端------end

// 管理中心
const ManagementCenter = lazy(() => import("view/managementCenter"));
// 商砼
// 车辆信息
const ManagementVehicle = lazy(
  () => import("view/managementCenter/information/vehicle")
);
// 车组信息
const ManagementCarGroup = lazy(
  () => import("view/managementCenter/information/carGroup")
);
// 终端信息
const ManagementTerminal = lazy(
  () => import("view/managementCenter/information/terminal")
);
// sim卡信息
const ManagementSimCard = lazy(
  () => import("view/managementCenter/information/simCard")
);

//sim卡信息 ==终端信息和设备管理
const ManagementSimCardN = lazy(
  () => import("view/intelligentOperation/device/simCard")
);

// 司机信息
const ManagementDriver = lazy(
  () => import("view/managementCenter/information/driver")
);
// 终端日志
const ManagementTerminalLog = lazy(
  () => import("view/managementCenter/log/terminalLog")
);
// 操作日志
const ManagementOperationLog = lazy(
  () => import("view/managementCenter/log/operationLog")
);

// 批量指令
const Instructions = lazy(
  () => import("view/managementCenter/rule/instructions")
);
// 电子围栏
const Fence = lazy(() => import("view/managementCenter/rule/fence"));
// 报警设置
const Alarm = lazy(() => import("view/managementCenter/rule/alarm"));
// 用户管理
const ManagementUser = lazy(
  () => import("view/managementCenter/authority/user")
);
// 角色管理
const ManagementRole = lazy(
  () => import("view/managementCenter/authority/role")
);
// 企业管理
const ManagementCompany = lazy(
  () => import("view/managementCenter/authority/company")
);
// 菜单管理
const ManagementMenu = lazy(
  () => import("view/managementCenter/authority/menu")
);
// 数据字典
const DataDictionary = lazy(
  () => import("view/managementCenter/system/dictionary")
);
// 实时评分设置
const RealtimeScore = lazy(
  () => import("view/managementCenter/system/realtimeScore")
);
// 日评分设置
const DataSetWeight = lazy(
  () => import("view/managementCenter/system/setweight")
);
// 企业参数
const EnParameters = lazy(
  () => import("view/managementCenter/system/enParameters")
);
// 平台参数
const Platform = lazy(() => import("view/managementCenter/system/platform"));
// 车标设置
const VehicleIcon = lazy(
  () => import("view/managementCenter/system/vehicleIcon")
);

// 缓存清理
const CacheClean = lazy(
  () => import("view/managementCenter/system/cacheClean")
);

// 抽图相关设置---抽图版本设置

const PictureVersionSetting = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedSetting/pictureVersionSetting"
    )
);

// 抽图相关设置---抽图水印设置
const PictureWatermarkSetting = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedSetting/pictureWatermarkSetting"
    )
);

// 抽图相关设置---抽图画质设置
const PictureQualitySetting = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedSetting/pictureQualitySetting"
    )
);
// 抽图相关设置---进线服务及范围配置
const LineScopeConfiguration = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedSetting/lineScopeConfiguration"
    )
);

// 抽图相关查询---画质获取查询
const ImageQualityAcquisition = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedInquire/imageQualityAcquisition"
    )
);

// 抽图相关查询---水印获取查询
const WatermarkAcquisitionQuery = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedInquire/watermarkAcquisitionQuery"
    )
);

// 抽图相关查询---未加入白名单查询
const NotAddWhiteListQuery = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedInquire/notAddWhiteListQuery"
    )
);
// 抽图相关查询---指令查询
const InstructionQuery = lazy(
  () =>
    import("view/intelligentOperation/pictureRelatedInquire/instructionQuery")
);

// 抽图相关统计---指令统计
const InstructionStatistics = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedStatistics/instructionStatistics"
    )
);

// 抽图相关统计---抽图失败统计
const PictureFailureStatistics = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedStatistics/pictureFailureStatistics"
    )
);

// 抽图相关统计---抽图失败终端统计
const PictureFailureTerminalStatistics = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedStatistics/pictureFailureTerminalStatistics"
    )
);

// 抽图相关统计---终端标签统计
const TerminalLabelStatistics = lazy(
  () =>
    import(
      "view/intelligentOperation/pictureRelatedStatistics/terminalLabelStatistics"
    )
);
const defaultMenu: RoutePropsConfig[] = [
  {
    // 首页
    name: "home",
    component: Home,
    icon: "home",
    intlTitle: "tx1801001",
  },
  {
    // 驾驶分析
    name: "drivingAnalysis",
    component: DrivingAnalysis,
    icon: "concrete",
  },
  {
    // echarts大屏
    name: "largeScreen",
    redirect: "/largeScreen",
    icon: "largeScreen",
    intlTitle: "tx1802001",
    isTabs: true,
  },
  {
    // 百度地图大屏
    name: "largeScreenBaidu",
    redirect: "/largeScreenBaidu",
    icon: "largeScreen",
    intlTitle: "tx1802001",
    isTabs: true,
  },
  {
    // 企业风控
    name: "companyControl",
    component: CompanyControl,
    icon: "companyControl",
    intlTitle: "tx1802003",
  },

  /** 巡检 */

  {
    name: "inspection",
    component: Inspection,
    icon: "companyControl",
    intlTitle: "tx1805102",
  },

  // ** 看板 - start */
  {
    name: "watchfulWaiting",
    component: WatchfulWaiting,
    icon: "companyControl",
    intlTitle: "tx0201001",
  },
  // ** 智能运维 - start */
  {
    name: "intelligentOperation",
    component: IntelligentOperation,
    intlTitle: "tx1805070",
    icon: "waybill",
  },
  {
    name: "deviceligentOperation",
    component: DeviceligentOperation,
    intlTitle: "tx1101086",
    icon: "waybill",
  },
  {
    name: "vanishingPoint",
    component: VanishingPoint,
    intlTitle: "tx1805072",
  },
  {
    name: "parameterSetting",
    component: ParameterSetting,
    intlTitle: "tx1805073",
  },
  {
    name: "terminalParameterSetting",
    component: TerminalParameterSetting,
    intlTitle: "tx1805074",
  },
  {
    name: "serverParameterSetting",
    component: ServerParameterSetting,
    intlTitle: "tx1805075",
  },
  {
    name: "dvrParameterDistribution",
    component: DvrParameterDistribution,
    intlTitle: "tx1805076",
  },
  {
    name: "comparisonPictures",
    component: ComparisonPictures,
    intlTitle: "tx1805078",
  },
  {
    name: "enterpriseAcceptance",
    component: EnterpriseAcceptance,
    intlTitle: "tx1805081",
  },

  {
    name: "strategyMaintenance",
    component: StrategyMaintenance,
    intlTitle: "tx1805083",
  },

  {
    name: "workOrderCreation",
    component: WorkOrderCreation,
    intlTitle: "tx1816002",
  },

  {
    name: "workOrderMine",
    component: WorkOrderMine,
    intlTitle: "tx1816006",
  },
  {
    name: "workOrderAssign",
    component: WorkOrderAssign,
    intlTitle: "tx1816009",
  },

  {
    name: "workOrderProcess",
    component: WorkOrderProcess,
    intlTitle: "tx1816011",
  },


  {
    name: "workOrderTrack",
    component: WorkOrderTrack,
    intlTitle: "tx1816010",
  },

  {
    name: "productAnalysisReport",
    component: ProductAnalysisReport,
    intlTitle: "tx1805085",
  },
  {
    name: "deviceAnalysisReport",
    component: DeviceAnalysisReport,
    intlTitle: "tx1101099",
  },
  {
    name: "upgradeStatisticalReport",
    component: UpgradeStatisticalReport,
    intlTitle: "tx1805086",
  },
  {
    name: "shipments",
    component: Shipments,
    intlTitle: "tx1101100",
  },
  {
    name: "versionStatistical",
    component: VersionStatistical,
    intlTitle: "tx0102001",
  },
  {
    name: "terminalFlowReport",
    component: TerminalFlowReport,
    intlTitle: "tx0114003",
  },
  {
    name: "enterpriseFlowReport",
    component: EnterpriseFlowReport,
    intlTitle: "tx0114004",
  },
  {
    name: "monthFlowReport",
    component: MonthFlowReport,
    intlTitle: "tx0114005",
  },
  {
    name: "terminalMonthFlowReport",
    component: TerminalMonthFlowReport,
    intlTitle: "tx0114010",
  },
  {
    name: "customerFlowReport",
    component: CustomerFlowReport,
    intlTitle: "tx0114011",
  },
  {
    name: "customerMonthFlowReport",
    component: CustomerMonthFlowReport,
    intlTitle: "tx0114012",
  },
  {
    name: "insuranceManagement",
    component: InsuranceManagement,
    intlTitle: "tx0112001",
  },
  {
    name: "bookingCustomers",
    component: BookingCustomers,
    intlTitle: "tx1101109",
  },
  // {
  //   name: 'installationManagement',
  //   component: InstallationManagement,
  //   intlTitle: 'tx1805089',
  // },
  {
    name: "appointmentQrCode",
    component: AppointmentQrCode,
    intlTitle: "tx1805090",
  },
  {
    name: "businessManagement",
    component: BusinessManagement,
    intlTitle: "tx1101101",
  },
  {
    name: "operatorManagement",
    component: OperatorManagement,
    intlTitle: "tx1101102",
  },
  {
    name: "userManagement",
    component: UserManagement,
    intlTitle: "tx1805034",
  },

  {
    name: "customerManagement",
    component: CustomerManagement,
    intlTitle: "tx1101104",
  },
  {
    name: "customerOperatorManagement",
    component: CustomerOperatorManagement,
    intlTitle: "tx1101105",
  },
  {
    name: "customerRoleManagement",
    component: CustomerRoleManagement,
    intlTitle: "tx1101106",
  },
  {
    name: "manufacturerManagement",
    component: ManufacturerManagement,
    intlTitle: "tx1101103",
  },
  //抽图相关设置
  // 抽图相关设置---抽图版本设置
  {
    name: "pictureVersionSetting",
    component: PictureVersionSetting,
    intlTitle: "tx1102101",
  },

  // 抽图相关设置---抽图水印设置
  {
    name: "pictureWatermarkSetting",
    component: PictureWatermarkSetting,
    intlTitle: "tx1102102",
  },

  // 抽图相关设置---抽图画质设置
  {
    name: "pictureQualitySetting",
    component: PictureQualitySetting,
    intlTitle: "tx1102137",
  },
  // 抽图相关设置---进线服务及范围配置
  {
    name: "lineScopeConfiguration",
    component: LineScopeConfiguration,
    intlTitle: "tx1102103",
  },
  // 抽图相关查询
  // 抽图相关查询---画质获取查询
  {
    name: "imageQualityAcquisition",
    component: ImageQualityAcquisition,
    intlTitle: "tx1102105",
  },
  // 抽图相关查询---水印获取查询
  {
    name: "watermarkAcquisitionQuery",
    component: WatermarkAcquisitionQuery,
    intlTitle: "tx1102106",
  },

  // 抽图相关查询---未加入白名单查询
  {
    name: "notAddWhiteListQuery",
    component: NotAddWhiteListQuery,
    intlTitle: "tx1102107",
  },

  // 抽图相关查询---指令查询
  {
    name: "instructionQuery",
    component: InstructionQuery,
    intlTitle: "tx1102108",
  },
  // 抽图相关统计---指令统计
  {
    name: "instructionStatistics",
    component: InstructionStatistics,
    intlTitle: "tx1102125",
  },

  // 抽图相关统计---抽图失败统计
  {
    name: "pictureFailureStatistics",
    component: PictureFailureStatistics,
    intlTitle: "tx1102127",
  },

  // 抽图相关统计---抽图失败终端统计
  {
    name: "pictureFailureTerminalStatistics",
    component: PictureFailureTerminalStatistics,
    intlTitle: "tx1102132",
  },

  // 抽图相关统计---终端标签统计
  {
    name: "terminalLabelStatistics",
    component: TerminalLabelStatistics,
    intlTitle: "tx1102133",
  },

  {
    name: "manufacturerOperatorManagement",
    component: ManufacturerOperatorManagement,
    intlTitle: "tx1101107",
  },
  {
    name: "manufacturerRoleManagement",
    component: ManufacturerRoleManagement,
    intlTitle: "tx1101108",
  },
  {
    name: "command",
    component: Command,
    intlTitle: "tx1101094",
  },
  {
    name: "deviceInfo",
    component: DeviceInfo,
    intlTitle: "tx1805014",
  },
  {
    name: "deviceManagement",
    component: DeviceManagement,
    intlTitle: "tx1805018",
  },
  {
    name: "deviceUpgrade",
    component: DeviceUpgrade,
    intlTitle: "tx1101093",
  },
  {
    name: "deviceVersion",
    component: DeviceVersion,
    intlTitle: "tx1101095",
  },
  {
    name: "latestSelfCheck",
    component: LatestSelfCheck,
    intlTitle: "tx1101091",
  },
  {
    name: "pastSelfCheck",
    component: PastSelfCheck,
    intlTitle: "tx1101092",
  },
  {
    name: "photo",
    component: Photo,
    intlTitle: "tx1101096",
  },
  {
    name: "picturePhoto",
    component: PicturePhoto,
    intlTitle: "tx1101113",
  },
  {
    name: "whiteList",
    component: WhiteList,
    intlTitle: "tx0114008",
  },
  {
    name: "blackList",
    component: BlackList,
    intlTitle: "tx0114009",
  },
  {
    name: "enterpriseWhiteList",
    component: EnterpriseWhiteList,
    intlTitle: "tx0114013",
  },
  /** 终端运维 - end */

  /** 设备运维 - start */
  {
    name: "deviceManagementd",
    component: DeviceManagementd,
    intlTitle: "tx0110001",
  },
  {
    name: "latestSelfCheckd",
    component: LatestSelfCheckd,
    intlTitle: "tx0109001",
  },
  {
    name: "pastSelfCheckd",
    component: PastSelfCheckd,
    intlTitle: "tx0108001",
  },
  {
    name: "installationAppointment",
    component: InstallationAppointment,
    intlTitle: "tx1805079",
  },
  {
    name: "installationAcceptance",
    component: InstallationAcceptance,
    intlTitle: "tx1805080",
  },
  {
    name: "equipmentAcceptance",
    component: EquipmentAcceptance,
    intlTitle: "tx1101098",
  },
  {
    name: "businessManagementd",
    component: BusinessManagementd,
    intlTitle: "tx1101084",
  },
  {
    name: "commandd",
    component: Commandd,
    intlTitle: "tx0109005",
  },

  {
    name: "operatorManagementd",
    component: OperatorManagementd,
    intlTitle: "tx1101102",
  },
  {
    name: "userManagementd",
    component: UserManagementd,
    intlTitle: "tx1805034",
  },
  {
    name: "manufacturerManagementd",
    component: ManufacturerManagementd,
    intlTitle: "tx1101103",
  },
  {
    name: "manufacturerOperatorManagementd",
    component: ManufacturerOperatorManagementd,
    intlTitle: "tx1101107",
  },
  {
    name: "manufacturerRoleManagementd",
    component: ManufacturerRoleManagementd,
    intlTitle: "tx1101108",
  },
  {
    name: "customerManagementd",
    component: CustomerManagementd,
    intlTitle: "tx1101110",
  },
  {
    name: "customerOperatorManagementd",
    component: CustomerOperatorManagementd,
    intlTitle: "tx1101111",
  },
  {
    name: "customerRoleManagementd",
    component: CustomerRoleManagementd,
    intlTitle: "tx1101112",
  },

  /** 设备运维 - end */

  /** 安装厂商端- start */
  {
    name: "installManufacturer",
    component: InstallManufacturer,
    icon: "waybill",
    intlTitle: "tx0301001",
  },
  {
    name: "manufacturerAcceptance",
    component: ManufacturerAcceptance,
    intlTitle: "tx0114001",
  },

  /** 安装厂商端- end */

  /** 管理中心 - start */
  {
    name: "managementCenter",
    component: ManagementCenter,
    icon: "managementCenter",
    intlTitle: "tx1805001",
  },
  {
    name: "vehicle",
    component: ManagementVehicle,
    intlTitle: "tx1805017",
  },
  {
    name: "carGroup",
    component: ManagementCarGroup,
    intlTitle: "tx1805013",
  },
  {
    name: "terminal",
    component: ManagementTerminal,
    intlTitle: "tx1805014",
  },
  {
    name: "simCard",
    component: ManagementSimCard,
    intlTitle: "tx1805015",
  },
  {
    name: "simCardN",
    component: ManagementSimCardN,
    intlTitle: "tx1805015",
  },
  {
    name: "driver",
    component: ManagementDriver,
    intlTitle: "tx1805016",
  },
  // 规则管理
  {
    name: "instructions",
    component: Instructions,
    intlTitle: "tx1805022",
  },
  {
    name: "fence",
    component: Fence,
    intlTitle: "tx1805023",
  },
  {
    name: "alarmSetting",
    component: Alarm,
    intlTitle: "tx1805024",
  },

  /** 权限管理 */
  {
    name: "user",
    component: ManagementUser,
    intlTitle: "tx1805031",
  },
  {
    name: "company",
    component: ManagementCompany,
    intlTitle: "tx1805035",
  },
  {
    name: "menu",
    component: ManagementMenu,
    intlTitle: "tx1805036",
  },
  {
    name: "role",
    component: ManagementRole,
    intlTitle: "tx1805034",
  },
  {
    name: "dictionary",
    component: DataDictionary,
    intlTitle: "tx1805052",
  },

  // 缓存清理设置
  {
    name: "cacheClean",
    component: CacheClean,
    intlTitle: "tx18050511",
  },

  {
    name: "realtimeScore",
    component: RealtimeScore,
    intlTitle: "tx1805056",
  },
  {
    name: "setWeight",
    component: DataSetWeight,
    intlTitle: "tx1805053",
  },
  {
    name: "enParameters",
    component: EnParameters,
    intlTitle: "tx1805057",
  },
  {
    name: "platform",
    component: Platform,
    intlTitle: "tx1805054",
  },
  /** 车标设置 */
  {
    name: "vehicleIcon",
    component: VehicleIcon,
    intlTitle: "tx1805058",
  },
  /** 日志管理 */
  {
    name: "terminalLog",
    component: ManagementTerminalLog,
    intlTitle: "tx1805043",
  },
  {
    name: "operationLog",
    component: ManagementOperationLog,
    intlTitle: "tx1805042",
  },
  {
    name: "cacheLoading",
    component: CacheLoading,
    intlTitle: "tx1805101",
  },
  /** 管理中心 - end */
];

export default defaultMenu;
