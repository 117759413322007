/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-11-13 14:08:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-22 16:42:54
 */
import { useCallback, useEffect, useRef } from 'react'
import { useSetState } from 'react-use'
import { Obj } from 'util/interface'
import { AxiosConfig, AxiosConfigWithHandleRes, Return, UseAxiosState } from './interface'
import { isBoolean, isFunction } from 'lodash-es'

type ConfigType<P, R, T> = AxiosConfig<P, R> | AxiosConfigWithHandleRes<P, R, T>

function isWithHandleRes<P, R, T>(value: Obj): value is AxiosConfigWithHandleRes<P, R, T>
function isWithHandleRes(value: any) {
  return !!value.handleResult
}

/** 有 handleResult 函数的重载 */
function useAxios<P, R, T>(
  config: AxiosConfigWithHandleRes<P, R, T>,
): Return<AxiosConfigWithHandleRes<P, R, T>, T>
/** 没有 handleResult 函数的重载 */
function useAxios<P, R>(config: AxiosConfig<P, R>): Return<AxiosConfig<P, R>, R>

function useAxios<P, R, T>(_config: ConfigType<P, R, T>) {
  const { defaultStart, ...extra } = _config
  const start = useRef(isBoolean(_config.defaultStart) ? _config.defaultStart : true)
  const [config, setConfig] = useSetState<ConfigType<P, R, T>>(extra)
  const [state, setState] = useSetState<UseAxiosState<R | T, P>>({
    data: undefined,
    loading: false,
    success: false,
    payload: undefined,
  })

  useEffect(() => {
    setState({ payload: config.payload })
  }, [config.payload, setState])

  useEffect(() => {
    if (start.current) {
      const { axios, payload = {}, onSuccess, onError, onFinally } = config
      const query = async () => {
        setState({ loading: true, success: false })
        const res = axios && (await axios(payload as P))
        if (res) {
          /** res如果是流文件，则直接返回res，否则返回res.data */
          let data: any = res instanceof Blob ? res : res.data
          if (isWithHandleRes(config)) {
            const { handleResult } = config
            data = handleResult(data)
          }
          setState({ data, loading: false, success: true })
          return data
        } else {
          setState({ loading: false, success: false })
          return Promise.reject()
        }
      }
      query().then(onSuccess, onError).finally(onFinally)
    } else {
      start.current = true
    }
  }, [config, setConfig, setState])

  const promise: Return<ConfigType<P, R, T>, R | T>[1] = useCallback(
    state =>
      new Promise((resolve, reject) => {
        setConfig(config => {
          const { onSuccess, onError, defaultStart: _defaultStart, ...extra } = {
            ...config,
            ...(isFunction(state) ? state(config) : state),
          }
          start.current = _defaultStart !== false
          return {
            ...extra,
            onSuccess: (value: any) => {
              onSuccess && onSuccess(value)
              resolve(value)
            },
            onError: () => {
              onError && onError()
              reject()
            },
          }
        })
      }),
    [setConfig],
  )

  return [state, promise]
}

export default useAxios
