/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-06-08 17:47:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 10:05:31
 */
import {ReactText} from 'react'
import {cloneDeep, differenceBy, isArray, isNil, isUndefined} from 'lodash-es'
import IntlMessageFormat from 'intl-messageformat'
import {ColProps} from 'antd/lib/grid'
import {intlStore} from 'store'
import {BasicCode} from 'server/web/basicCode'
import {downloadFileByPath} from 'server/web/fileManager'
import proxy, {BASEURL} from 'server/proxy'
import {Option} from './interface'
import moment from 'moment'
import {webAPIResponse} from 'server/web/index.globals'

type BasicCodesItem = webAPIResponse.BasicCodesItem

/** 当前是否是测试或者生产环境 */
export const isProduction = () => process.env.NODE_ENV === 'production'
/** 当前是否是开发环境 */
export const isDevelopment = () => process.env.NODE_ENV === 'development'

/**
 * 设置localStorage值
 * @param key string
 * @param data 数据，string或者泛型
 */
export const setLocal = <T>(key: string, data: string | T): void => {
  try {
    const dataStr: string = typeof data !== 'object' ? (data as string) : JSON.stringify(data)
    localStorage.setItem(key, dataStr)
  } catch (error) {
    new Error(error)
  }
}

/**
 * 获取 localStorage 对应的值
 * @param key string
 * @returns data string类型或者null
 */
export const getLocal = (key: string): string | null => {
  return localStorage.getItem(key)
}
/**
 * 获取 localStorage 对应的值
 * @param key string
 * @returns data object类型或者null
 */
export const getLocalJson = <T>(key: string): T | null => {
  let objStr = localStorage.getItem(key)
  try {
    return objStr ? JSON.parse(objStr) : ''
  } catch (error) {
    new Error(error)
    return null
  }
}
/**
 * 清除指定 localStorage 的item
 * @param keys string或者string[]
 */
export const removeLocal = (keys: string | string[]): void => {
  // 删除localStorage keys分两种 1.单个的字符串 2.数组
  if (typeof keys === 'string') {
    localStorage.removeItem(keys)
  }
  if (Array.isArray(keys)) {
    for (const item of keys) {
      localStorage.removeItem(item)
    }
  }
}

/**
 * 数组转树
 * @param data 树形结构数据
 */
export const arrayToTree = (data: any, id = 'id', pId = 'pId', topId = '-1'): any => {
  try {
    let _data: any = cloneDeep(data) // 深拷贝
    let tempObj: any = {}

    for (let i in _data) {
      tempObj[_data[i][id]] = _data[i]
    }

    return _data.filter((father: any) => {
      // 把当前节点的所有子节点找到
      let childArr = _data.filter((child: any) => father[id] === child[pId])

      if (childArr.length > 0) {
        father.children = childArr
      }

      return father[pId] === topId || !tempObj[father[pId]]
    })
  } catch (error) {
    return []
  }
}

/**
 * 将任意树形结构的数据转化为满足SelectTree和antd的tree的数据
 * @description 为树形结构数据添加title属性和key属性和value属性
 * @param arr
 */
export const handleTreeData = (
  arr: { children: any[]; [key: string]: any }[],
  rule?: { title?: string; label?: string; key?: string | string[]; value?: string | string[] },
): BasicCode[] => {
  if (rule) {
    const handleItem = (data: { children: any[]; [key: string]: any }) => {
      if (rule.title) {
        const {title} = rule
        data.title = data[title]
      }
      if (rule.label) {
        const {label} = rule
        data.label = data[label]
      }
      if (rule.key) {
        const {key} = rule
        if (typeof key === 'string') {
          data.key = data[key]
        } else if (Array.isArray(key)) {
          const arr = key.map(item => data[item])
          data.key = arr.join()
        }
      }

      if (rule.value) {
        const {value} = rule
        if (typeof value === 'string') {
          data.value = data[value]
        } else if (Array.isArray(value)) {
          const arr = value.map(item => data[item])
          data.value = arr.join()
        }
      }

      if (data.children) {
        data.children.forEach(handleItem)
      }
    }
    arr.forEach(data => {
      handleItem(data)
    })
  }
  return arr as BasicCode[]
}

/**
 * 处理树形结构的key
 * @description 将children中的key转换为‘父key,子key’的形式，并且只转换一层
 * @param arr
 */
export const handleTreeDataKey = (arr: BasicCode[]): BasicCode[] => {
  const handleKey = <T extends BasicCode>(parentKey: string, item: T) => {
    const {key} = item
    item.key = `${parentKey}_${key}`
    return item
  }
  arr.forEach(item => {
    const parentKey = item.key
    item.children = item.children.map(item => handleKey(parentKey, item))
  })
  return arr
}

/**
 * 获取国际化文案
 * @param id
 * @param values
 */

export const getLangMessage = (
  obj: { id: string; description?: string; defaultMessage?: string },
  values?: { [key: string]: string | number },
): string => {
  const {id, defaultMessage} = obj
  const langMessage: any = intlStore.intl
  let selfValues: any = {}

  if (values) {
    for (const key in values) {
      selfValues[key] = values[key] in langMessage ? langMessage[values[key]] : values[key]
    }
  }

  const _value = id in langMessage ? langMessage[id] : defaultMessage || id

  return values ? new IntlMessageFormat(_value).format(selfValues) : _value
}

/** @description 请输入XXX，最多支持两个字符串拼接 */
export const pleaseEnter = (obj: {
  id: string | [string, string]
  description?: string
  defaultMessage?: string
}) => {
  return typeof obj.id === 'string'
    ? getLangMessage({id: 'tx000202'}, {value: obj.defaultMessage || obj.id})
    : getLangMessage(
      {id: 'tx000203'},
      {value: obj.defaultMessage || obj.id[0], value2: obj.defaultMessage ? '' : obj.id[1]},
    )
}

/** @description 请选择XXX，最多支持两个字符串拼接 */
export const pleaseSelect = (obj: {
  id: string | [string, string]
  description?: string
  defaultMessage?: string
}) => {
  return typeof obj.id === 'string'
    ? getLangMessage({id: 'tx000201'}, {value: obj.defaultMessage || obj.id})
    : getLangMessage(
      {id: 'tx000208'},
      {value: obj.defaultMessage || obj.id[0], value2: obj.defaultMessage ? '' : obj.id[1]},
    )
}

export const createScript = (src: string) => {
  return new Promise((resolve: any) => {
    const BMap = (window as any).BMap
    if (typeof BMap !== 'undefined') {
      resolve(BMap)
      return true
    }

    ;(window as any).onBMapCallback = function () {
      resolve(BMap)
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    const headElement = document.head || document.getElementsByTagName('head')[0]
    headElement.appendChild(script)
    return true
  })
}

/**
 * 最大选择数处理函数
 * @param prevData 上次的勾选
 * @param nextData 最新的勾选
 * @param maxLength 最大勾选数
 */
export const MaxCheck = (prevData: any[], nextData: any[], maxLength: number) => {
  if (prevData && prevData.length) {
    const diffLength = maxLength - prevData.length
    // 判断上次勾选的数量是否达到最大值
    if (diffLength > 0) {
      const diffArr = differenceBy(prevData, nextData, 'id')
      return [...prevData, ...diffArr.splice(0, diffLength)]
    }
    return prevData
  } else {
    return nextData.slice(0, maxLength)
  }
}

/**
 * 拼接完整的url
 * @param url 后端传过来的url
 * @param download 是否进行下载,默认 false
 * @default false
 */
export const createDownLoadUrl = (url?: string, download?: boolean) => {
  if (!url) {
    return ''
  }
  const _url = `${BASEURL}${proxy}/webapi/filenamager/getFileByPath?path=${url}`
  if (download) {
    fileDownload(_url)
  }
  return _url
}
export const createDownLoadUrl2 = (url?: string, download?: boolean) => {
  if (!url) {
    return ''
  }
  const _url = `${BASEURL}${proxy}/webapi/filenamager/getFileByAllPath?path=${url}`
  if (download) {
    fileDownload(_url)
  }
  return _url
}
/**
 * 拼接完整的ftp服务器的url
 * @param dir 后端传过来的ftp的url
 * @param download 是否进行下载,默认 false
 * @default false
 */
export const createDownLoadUrl_Ftp = (dir?: string, download?: boolean) => {
  if (!dir) return ''
  const _url = `${BASEURL}${proxy}/webapi/filenamager/getZipByPathWithFtp?dir=${dir}`
  if (download) {
    fileDownload(_url)
  }
  return _url
}

/** 文件流下载
 * @param url 文件路径
 */
export const downLoadFile = async (url: string, type: string = 'down'): Promise<any> => {
  if (url) {
    const result = await downloadFileByPath(url)
    if (result) {
      if (type === 'down') {
        fileDownload(result as any)
      } else {
        return URL.createObjectURL(result)
      }
    }
  }
}


/**
 * 文件转换，参数为后端传过来的Blob对象或者url地址
 * @description 为了获得这个Blob对象，须在axios拦截器的exportUrls数组里面加上请求接口的url地址
 * @param {Blob | string} blob
 */
export const fileDownload = (blob: Blob | string, fileType?: string) => {
  let url = ''
  if (blob instanceof Blob) {
    url = isUndefined(fileType)
      ? URL.createObjectURL(blob)
      : URL.createObjectURL(new Blob([blob], {type: fileType}))
  } else if (typeof blob === 'string') {
    url = blob
  }
  const a = document.createElement('a')
  const name = moment(new Date()).format('YYYYMMDDHHmmss')
  a.setAttribute('href', url)
  a.setAttribute('download', name)
  a.setAttribute('target', '_blank')
  a.click()
  URL.revokeObjectURL(url)
  a.remove()
}

/**
 * 处理表单的布局
 * @param {number} label
 * @param {number} wrapper
 * 只传一个label则根据label模糊计算布局，处理结果约等于每行label列，
 * label和wrapper都传，则根据传入参数精确配置表单布局
 */
export function handleLayout(
  label: number,
  wrapper: number,
): { labelCol: ColProps; wrapperCol: ColProps }
export function handleLayout(label: number): { labelCol: ColProps; wrapperCol: ColProps }
export function handleLayout(label: number, wrapper?: number) {
  const config = isNil(wrapper)
    ? {
      labelCol: {span: label * 3},
      wrapperCol: {span: 24 - label * 3},
    }
    : {
      labelCol: {span: label},
      wrapperCol: {span: wrapper},
    }
  return config
}

type Type = 'boolean' | 'string' | 'number'

/**
 * 创建一个只含（是/否）的option，value值根据传入类型设置
 * @param type
 */
export function getBaseOption(type: 'boolean'): Array<Option<any>>
export function getBaseOption(type: 'string'): Array<Option<string>>
export function getBaseOption(type: 'number'): Array<Option<number>>
export function getBaseOption(type: Type) {
  let Yes,
    No = undefined
  switch (type) {
    case 'boolean':
      Yes = true
      No = false
      break
    case 'number':
      Yes = 1
      No = 0
      break
    case 'string':
      Yes = '1'
      No = '0'
      break
    default:
      break
  }
  return [
    {
      label: getLangMessage({id: 'tx000185', description: '是'}),
      value: Yes,
    },
    {
      label: getLangMessage({id: 'tx000186', description: '否'}),
      value: No,
    },
  ]
}

/**
 * 把时间戳转换成时间
 * @param time 时间
 * @returns mm:ss 返回分秒
 */
export const timeFormat = (time: number | undefined): string => {
  let timeStr = '00:00'
  if (time && time > 0) {
    if (time >= 60) {
      const m = Math.floor(time / 60)
      const s = time - m * 60
      const mStr = m > 9 ? m : '0' + m
      const sStr = s > 9 ? s : '0' + s
      timeStr = `${mStr}:${sStr}`
    } else {
      const sStr = time > 9 ? time : '0' + time
      timeStr = `00:${sStr}`
    }
  }
  return timeStr
}

export const basicCodesToOption = (basicCodes: BasicCodesItem): Option => ({
  ...basicCodes,
  label: basicCodes.text,
  value: basicCodes.key,
})

/**
 * 根据key值在basicCodes中查找文本描述，方便详情显示，key可以是数组
 * @param {ReactText | ReactText[]} key
 * @param basicCodes
 */
export function handlebasicCodesValue(
  key: ReactText,
  basicCodes?: BasicCodesItem[],
): string | undefined
export function handlebasicCodesValue(
  key: ReactText[],
  basicCodes?: BasicCodesItem[],
): string | undefined
export function handlebasicCodesValue(key: ReactText | ReactText[], basicCodes?: BasicCodesItem[]) {
  if (!basicCodes) return
  if (isArray(key)) {
    return (
      key
        // eslint-disable-next-line eqeqeq
        .map(k => basicCodes.findIndex(item => item.key == k))
        .map(i => basicCodes[i].text)
        .join()
    )
  } else {
    // eslint-disable-next-line eqeqeq
    return basicCodes[basicCodes.findIndex(item => item.key == key)].text
  }
}

interface ChangeProjectNameProps {
  loginName: string
  logoName: string
}

export const changeProjectName = (logo?: string): ChangeProjectNameProps => {
  let _logo: string = ''
  let _title: string = ''

  switch (process.env.REACT_APP_TYPE) {
    case 'concrete':
      _title = getLangMessage({id: 'tx100003', description: '天砼智能调度系统'})
      break
    case 'tiantong':
      _title = getLangMessage({id: 'tx100005', description: '商砼智能调度系统'})
      break
    default:
      _title = getLangMessage({id: 'tx100001', description: '主动安全监控平台'})
  }

  if (logo) {
    _logo = logo
  } else {
    _logo =
      process.env.REACT_APP_TYPE === 'concrete'
        ? getLangMessage({id: 'tx100004', description: '商砼智能调度系统'})
        : getLangMessage({id: 'tx100002', description: '天砼智能调度系统'})
    switch (process.env.REACT_APP_TYPE) {
      case 'concrete':
        _logo = getLangMessage({id: 'tx100003', description: '天砼智能调度系统'})
        break
      case 'tiantong':
        _logo = getLangMessage({id: 'tx100005', description: '商砼智能调度系统'})
        break
      default:
        _logo = getLangMessage({id: 'tx100001', description: '主动安全监控平台'})
    }
  }

  return {
    loginName: _title,
    logoName: _logo,
  }
}

/**
 * 获取url参数 转换为对象
 * @param search
 */
export const urlSearchParams = <T>(search: string): T => {
  const arr: Array<string> =
    search.indexOf('?') === 0 ? search.substr(1).split('&') : search.split('&')
  const obj: any = {}

  arr.forEach(item => {
    const _arr = item.split('=')
    obj[_arr[0]] = _arr[1]
  })
  return obj
}
